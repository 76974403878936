import React from "react";
import { CircuitBoard, Layers, Smile, Star } from "lucide-react";
import {
  TechnicalSkillExtended,
  NamedAttribute,
  BehavioralAttribute,
  TechnicalGroupKey,
  BehavioralGroupKey,
  JobTitleBucketKey,
} from "./interfaces";

const titleMap: Record<string, string> = {
  group1: "Group 1",
  group2: "Group 2",
  group3: "Group 3",
  group4: "Group 4",
  software: "Software",
  data: "Data",
  finance: "Finance",
  engineering: "Engineering",
  management: "Management",
  expression: "Expression",
  resilience: "Resilience",
  other: "Other",
} as const;

interface SkillBarProps {
  name: string;
  rating: number;
  recommended: boolean;
  selected: boolean;
  onToggle: () => void;
  color?: string;
}

const SkillBar: React.FC<SkillBarProps> = ({
  name,
  rating,
  recommended,
  selected,
  onToggle,
  color = "bg-blue-500",
}) => {
  const getLevel = (value: number): string => {
    if (value >= 90) return "World Class";
    if (value >= 80) return "Expert";
    if (value >= 70) return "Advanced";
    return "Intermediate";
  };

  return (
    <div className="flex flex-col gap-1 w-full mb-3">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-900 font-medium truncate">
            {name}
          </span>
          {recommended && (
            <Star className="w-3 h-3 fill-yellow-400 text-yellow-400" />
          )}
        </div>
        <div className="flex items-center gap-3">
          <span className="text-sm text-gray-900 font-medium">{rating}%</span>
          <span className="text-xs text-gray-600">{getLevel(rating)}</span>
          <label className="flex items-center gap-1">
            <input
              type="checkbox"
              checked={selected}
              onChange={onToggle}
              className="h-3 w-3 rounded border-gray-300"
            />
            <span className="text-xs">Show</span>
          </label>
        </div>
      </div>
      <div className="w-full bg-gray-100 h-2 rounded-full">
        <div
          className={`${color} h-full rounded-full`}
          style={{ width: `${rating}%` }}
        />
      </div>
    </div>
  );
};

interface CategorySectionProps {
  title: string;
  skills: SkillBarProps[];
  color: string;
}

const CategorySection: React.FC<CategorySectionProps> = ({
  title,
  skills,
  color,
}) => (
  <div className="mb-6">
    <h5 className="text-sm font-medium text-gray-700 uppercase tracking-wider mb-3 border-b border-gray-200 pb-2">
      {title}
    </h5>
    <div className="space-y-3">
      {skills.map((skill) => (
        <SkillBar key={skill.name} {...skill} color={color} />
      ))}
    </div>
  </div>
);

interface ResultsColumnProps {
  title: string;
  icon: React.FC<any>;
  sections: {
    title: string;
    skills: SkillBarProps[];
  }[];
  color: string;
}

const ResultsColumn: React.FC<ResultsColumnProps> = ({
  title,
  icon: Icon,
  sections,
  color,
}) => (
  <div className="flex flex-col bg-white rounded-lg p-6 shadow-sm border border-gray-100">
    <div className="flex items-center gap-3 mb-6">
      <Icon className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
      <h4 className="text-xl font-semibold text-gray-900">{title}</h4>
    </div>
    <div className="space-y-6">
      {sections.map(({ title, skills }) => (
        <CategorySection
          key={title}
          title={title}
          skills={skills}
          color={color}
        />
      ))}
    </div>
  </div>
);

interface TestCenterResultsProps {
  technicalGroups: Record<TechnicalGroupKey, TechnicalSkillExtended[]>;
  backgroundGroups: Record<JobTitleBucketKey, NamedAttribute[]>;
  behavioralGroups: Record<
    BehavioralGroupKey,
    Record<string, BehavioralAttribute>
  >;
  onTechnicalToggle: (groupKey: TechnicalGroupKey, skillName: string) => void;
  onBackgroundToggle: (bucketKey: JobTitleBucketKey, attrName: string) => void;
  onBehavioralToggle: (
    attributeName: string,
    groupName: BehavioralGroupKey,
  ) => void;
}

const TestCenterResults: React.FC<TestCenterResultsProps> = ({
  technicalGroups,
  backgroundGroups,
  behavioralGroups,
  onTechnicalToggle,
  onBackgroundToggle,
  onBehavioralToggle,
}) => {
  const technicalSections = Object.entries(technicalGroups).map(
    ([key, skills]) => ({
      title: titleMap[key] || key,
      skills: skills.map((skill) => ({
        name: skill.name,
        rating: skill.rating,
        recommended: skill.recommended,
        selected: skill.selected,
        onToggle: () => onTechnicalToggle(key as TechnicalGroupKey, skill.name),
      })),
    }),
  );

  const backgroundSections = Object.entries(backgroundGroups).map(
    ([key, items]) => ({
      title: titleMap[key] || key,
      skills: items.map((item) => ({
        name: item.name,
        rating: item.rating,
        recommended: item.recommended,
        selected: item.selected,
        onToggle: () => onBackgroundToggle(key as JobTitleBucketKey, item.name),
      })),
    }),
  );

  const behavioralSections = Object.entries(behavioralGroups).map(
    ([key, attributes]) => ({
      title: titleMap[key] || key,
      skills: Object.entries(attributes).map(([name, attr]) => ({
        name,
        rating: attr.rating,
        recommended: attr.recommended,
        selected: attr.selected,
        onToggle: () => onBehavioralToggle(name, key as BehavioralGroupKey),
      })),
    }),
  );

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 text-xs text-gray-600">
        <Star className="w-3 h-3 fill-yellow-400 text-yellow-400" />
        <span>Recommended to show to employers</span>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <ResultsColumn
          title="Technical"
          icon={CircuitBoard}
          sections={technicalSections}
          color="bg-blue-500"
        />
        <ResultsColumn
          title="Background"
          icon={Layers}
          sections={backgroundSections}
          color="bg-green-500"
        />
        <ResultsColumn
          title="Behavioral"
          icon={Smile}
          sections={behavioralSections}
          color="bg-green-400"
        />
      </div>
    </div>
  );
};

export default TestCenterResults;
