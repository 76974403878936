import { Offcanvas } from "react-bootstrap";
import { SelectedCourseDrawerProps } from "./types";
import ProgressIndicator from "../../components/ProgressIndicator";
import { PrimaryButton } from "../../components/Button";
import CourseStatusChip from "./CourseStatusChip";

const SelectedCourseDrawer: React.FC<SelectedCourseDrawerProps> = ({
  showDrawer,
  selectedCourse,
  selectedCoursePrerequisites,
  selectedCourseCitation,
  lecturePreview,
  lecturesProgress,
  courseStatusChipProps,
  handleHide,
  handleNavigateToCourse,
}) => {
  return (
    <Offcanvas
      show={showDrawer}
      backdrop={false}
      placement="end"
      scroll={true}
      onHide={() => handleHide(selectedCourse)}
    >
      <Offcanvas.Header className="w-full bg-accent-primary-dimmer p-0">
        <Offcanvas.Title className="w-full bg-accent-primary-dimmer flex flex-row pl-4 pr-2 py-3 items-center justify-between">
          <h2 className="text-background font-semibold text-2xl">
            Course Preview
          </h2>

          <button
            onClick={() => handleHide(selectedCourse)}
            className="w-8 h-8 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M11 11.5L4 4.5M11 4.5L4 11.5"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        className="w-full p-0"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col justify-between h-full p-0">
          <div className="flex flex-col gap-8 flex-1 mt-8">
            <div className="flex flex-col gap-4 justify-start items-start px-4 py-3">
              <div className="w-full flex flex-col items-start">
                <h3 className="font-semibold text-2xl">
                  {selectedCourse.courseNumber} {selectedCourse.name}
                </h3>
              </div>
              <CourseStatusChip
                courseStatusChipProps={courseStatusChipProps}
              ></CourseStatusChip>
              <p className="text-[0.75rem] text-foreground-dimmer">
                {selectedCourseCitation}
              </p>
              {selectedCoursePrerequisites.length > 0 ? (
                <div className="flex flex-col gap-1">
                  <p className="text-[0.75rem] text-foreground-dimmer">
                    Prerequisites:{" "}
                  </p>
                  <div className="flex flex-col">
                    {selectedCoursePrerequisites.map(
                      (prerequisiteCourse, index) => (
                        <p
                          className="text-[0.75rem] text-foreground-dimmer"
                          key={index}
                        >
                          {prerequisiteCourse.courseNumber}{" "}
                          {prerequisiteCourse.name}
                        </p>
                      ),
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="flex flex-col justify-start items-start px-4">
              {lecturesProgress.map((lectureProgress, index) => (
                <ProgressIndicator
                  progress={lectureProgress.progress}
                  showConnectionLine={index !== lecturesProgress.length - 1}
                  key={index}
                >
                  <p className="font-semibold text-foreground text-sm">
                    {lectureProgress.lectureType}
                  </p>
                  <p className="text-foreground-dimmer text-sm mb-4">
                    {Math.floor(
                      lectureProgress.progress * lectureProgress.totalLectures,
                    )}
                    /{lectureProgress.totalLectures} completed
                  </p>
                </ProgressIndicator>
              ))}
            </div>
            <div className="flex flex-col gap-4 justify-start items-start px-4 py-3">
              <div className="w-full flex flex-col items-start">
                <h3 className="font-semibold text-2xl">Lecture Preview</h3>
              </div>

              <div className="w-full min-h-[228px] h-full">
                {lecturePreview}
              </div>
            </div>
          </div>
          <div
            className="w-full bg-background-dimmer border-t-[1px] border-background-dimmest flex flex-col items-center justify-center px-4 py-3"
            tabIndex={0}
          >
            <PrimaryButton
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M12 7.5V13.25C12 13.4142 11.9677 13.5767 11.9048 13.7284C11.842 13.88 11.75 14.0178 11.6339 14.1339C11.5178 14.25 11.38 14.342 11.2284 14.4048C11.0767 14.4677 10.9142 14.5 10.75 14.5H3.25C2.91848 14.5 2.60054 14.3683 2.36612 14.1339C2.1317 13.8995 2 13.5815 2 13.25V5.75C2 5.41848 2.1317 5.10054 2.36612 4.86612C2.60054 4.6317 2.91848 4.5 3.25 4.5H8.48375M10.5 2.5H14V6M7 9.5L13.75 2.75"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              message="View Full Course"
              handleClick={() => {
                handleNavigateToCourse(selectedCourse);
              }}
            ></PrimaryButton>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SelectedCourseDrawer;
