import { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLoaderData } from "react-router-dom";
import "../../assets/courseAssets/main.css";
import "../../assets/courseAssets/main.scss";
import SEOWrapper from "../../components/SEOWrapper";
import {
  Course,
  CourseContent,
  CourseProgress,
  CourseStatusChipProps,
} from "./types";
import { ProgressIndicatorProps } from "../../components/ProgressIndicator";
import {
  fetchCompletedLectures,
  generateMITCitation,
  computeLecturesProgress,
  fetchAllCourses,
  fetchCoursesProgress,
  fetchUserId,
  fetchCourseRecommendations,
  isCourseMissingPrerequisites,
} from "./courseUtils";
import { toggleLectureCompletion } from "./courseUtils";
import ProgressIndicator from "../../components/ProgressIndicator";
import CourseStatusChip from "./CourseStatusChip";

export default function CourseOverview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = useLoaderData() as CourseContent;
  const [completedLectures, setCompletedLectures] = useState<string[]>([]);
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [allCourseProgress, setAllCoursesProgress] = useState<CourseProgress[]>(
    [],
  );
  const [recommendedCourses, setRecommendedCourses] = useState<Course[]>([]);

  useEffect(() => {
    const updateLectures = async () => {
      setCompletedLectures(await fetchCompletedLectures(content.id));
    };
    const updateCourses = async () => {
      const userId = fetchUserId();
      const courses = await fetchAllCourses();
      setAllCourses(courses);
      const coursesProgress = await fetchCoursesProgress(userId);
      setAllCoursesProgress(coursesProgress);
      setRecommendedCourses(
        await fetchCourseRecommendations(courses, coursesProgress),
      );
    };
    updateLectures();
    updateCourses();
  }, [content.id]);

  const currentCourseStatusChipProps = useMemo<CourseStatusChipProps>(() => {
    const currentCourse = allCourses.find((course) => course.id === content.id);
    if (!currentCourse) return { courseStatusChipProps: null };
    const selectedCourseProgress = allCourseProgress.find(
      (courseProgress) =>
        courseProgress.courseNumber === currentCourse.courseNumber,
    );
    if (!selectedCourseProgress) return { courseStatusChipProps: null };
    const recommended = recommendedCourses.some(
      (recommendedCourse) =>
        recommendedCourse.courseNumber === currentCourse.courseNumber,
    );
    const missingPrerequisites = isCourseMissingPrerequisites(
      currentCourse,
      allCourseProgress,
    );
    return {
      courseStatusChipProps: {
        courseProgress: selectedCourseProgress.progress,
        isRecommended: recommended,
        needsMorePrerequisites: missingPrerequisites,
      },
    };
  }, [allCourses, allCourseProgress, recommendedCourses, content.id]);

  const progressItemProps = useMemo<
    (ProgressIndicatorProps & HTMLAttributes<HTMLDivElement>)[][]
  >(() => {
    const handleToggleLectureCompletion = async (lectureId: string) => {
      const lectureIndex = completedLectures.indexOf(lectureId);
      if (lectureIndex >= 0) {
        completedLectures.splice(lectureIndex, 1);
        setCompletedLectures([...completedLectures]);
      } else setCompletedLectures([...completedLectures, lectureId]);
      await toggleLectureCompletion(content.id.replaceAll(" ", "-"), lectureId);
    };

    const handleSetLectureGroupCompleted = async (
      contentType: string,
      completed: boolean,
    ) => {
      const targetContentGroup = content.contentGroups.find(
        (contentGroup) => contentGroup.contentType === contentType,
      );
      if (targetContentGroup) {
        if (completed)
          setCompletedLectures([
            ...completedLectures.filter(
              (lectureId) =>
                !targetContentGroup.contentList.includes(lectureId),
            ),
          ]);
        else
          setCompletedLectures([
            ...new Set([
              ...targetContentGroup.contentList,
              ...completedLectures,
            ]),
          ]);

        const progressUpdates = targetContentGroup.contentList.map(
          (lectureId) =>
            (async () => {
              if (
                completed
                  ? completedLectures.includes(lectureId)
                  : !completedLectures.includes(lectureId)
              ) {
                await toggleLectureCompletion(
                  content.id.replaceAll(" ", "-"),
                  lectureId,
                );
              }
            })(),
        );
        await Promise.all(progressUpdates);
      }
    };

    let accumulatedLength = 0;
    const lecturesProgress = computeLecturesProgress(
      content,
      completedLectures,
    );
    const progressIndicatorGroups: (ProgressIndicatorProps &
      HTMLAttributes<HTMLDivElement>)[][] = [];
    content.contentGroups.forEach((contentGroup) => {
      const propList = [];
      const currentLectureTypeProgress = lecturesProgress.find(
        (lectureProgress) =>
          lectureProgress.lectureType === contentGroup.contentType,
      );
      const startingProgressProp: ProgressIndicatorProps &
        HTMLAttributes<HTMLDivElement> = {
        progress: currentLectureTypeProgress
          ? currentLectureTypeProgress.progress
          : 0,
        showConnectionLine: contentGroup.contentList.length !== 0,
        handleClick: () =>
          handleSetLectureGroupCompleted(
            contentGroup.contentType,
            currentLectureTypeProgress
              ? currentLectureTypeProgress.progress >= 1
              : false,
          ),
        children: [
          <div className="flex flex-col mb-4">
            <p className="text-2xl text-foreground">
              {contentGroup.contentType}
            </p>
            {currentLectureTypeProgress ? (
              <p>
                {Math.floor(
                  currentLectureTypeProgress.progress *
                    currentLectureTypeProgress.totalLectures,
                )}{" "}
                / {currentLectureTypeProgress.totalLectures} Completed
              </p>
            ) : (
              <p>0 / 0 Completed</p>
            )}
          </div>,
        ],
      };

      contentGroup.contentList.sort((a, b) => {
        const numberA = parseInt(a.match(/(\d+)/)?.[0] ?? "0", 10);
        const numberB = parseInt(b.match(/(\d+)/)?.[0] ?? "0", 10);
        return numberA - numberB;
      });

      propList.push(startingProgressProp);
      contentGroup.contentList.forEach((lectureId, fileIndex) => {
        const lectureProgressProp: ProgressIndicatorProps &
          HTMLAttributes<HTMLDivElement> = {
          progress: completedLectures.includes(lectureId) ? 1 : 0,
          showConnectionLine: fileIndex !== contentGroup.contentList.length - 1,
          children: (
            <div className="mb-8">
              <Link
                to={`lectures/${accumulatedLength + 1}`}
                className="no-underline hover:underline"
              >
                <p className="text-lg text-foreground ">{`${
                  contentGroup.contentType
                } ${fileIndex + 1}`}</p>
              </Link>
            </div>
          ),
          handleClick: () => handleToggleLectureCompletion(lectureId),
        };
        propList.push(lectureProgressProp);
        accumulatedLength++;
      });
      progressIndicatorGroups.push(propList);
    });

    return progressIndicatorGroups;
  }, [completedLectures, content]);

  return (
    <SEOWrapper
      title={`${content.title} - AlgoLink Course Overview`}
      description={`Explore the ${content.title} course by ${content.author}. Learn about ${content.title} through MIT OpenCourseWare content on AlgoLink.`}
    >
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col h-full w-full pt-6 2xl:pt-16 gap-10 max-w-[50rem] 2xl:max-w-[70rem] px-4">
          <div className="flex flex-col gap-4">
            <h3 className="font-semibold text-2xl">{content.title}</h3>
            <CourseStatusChip
              {...currentCourseStatusChipProps}
            ></CourseStatusChip>
          </div>
          <div className="flex flex-col-reverse md:flex-row flex-1 md:overflow-hidden gap-8 mb-8">
            <div className="min-w-fit h-full overflow-y-scroll scrollbar [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
              <div className="w-full h-full flex flex-col gap-8 pr-4">
                {progressItemProps.map((progressItemList, listIndex) => {
                  return (
                    <div className="flex flex-col mb-8" key={listIndex}>
                      {progressItemList.map((progressEntry, index) => (
                        <ProgressIndicator
                          {...progressEntry}
                          key={index}
                        ></ProgressIndicator>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex-1 h-full flex flex-col gap-6">
              <Outlet></Outlet>
              <p className="max-w-[80ch]">
                {generateMITCitation(
                  content.author,
                  content.title,
                  content.term,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </SEOWrapper>
  );
}
