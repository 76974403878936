import React from "react";
import ProcessBackground from "../../assets/img/landing/process-background.png";
import RecruitmentBackground from "../../assets/img/landing/recruitment-background.png";
import messagesExample from "../../assets/img/landing/messages-example.png";
import profileExample from "../../assets/img/landing/profile-example.png";
import testCenterExample from "../../assets/img/landing/test-center-example.png";
import courseMapExample from "../../assets/img/landing/course-map-example.png";
import companyJobsExample from "../../assets/img/landing/company-jobs-example.png";
import Number1 from "../../assets/img/landing/1.png";
import Number2 from "../../assets/img/landing/2.png";
import Number3 from "../../assets/img/landing/3.png";
import Number4 from "../../assets/img/landing/4.png";
import Number5 from "../../assets/img/landing/5.png";

interface ProcessStepProps {
  numberImage: string;
  title: string;
  description: string;
  backgroundImage: string;
  contentImage: string;
}

const ProcessStep: React.FC<ProcessStepProps> = ({
  numberImage,
  title,
  description,
  backgroundImage,
  contentImage,
}) => {
  const isProcessBackground = backgroundImage === ProcessBackground;
  const textColorClass = isProcessBackground ? "text-white" : "text-black";

  return (
    <div className="relative w-full h-[600px]">
      {/* Background with overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />

      {/* Content container with absolute positioning */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full max-w-6xl mx-auto px-4">
          <div className="flex flex-col lg:flex-row justify-between items-center gap-8">
            {/* Text content */}
            <div className="flex-1 max-w-xl flex flex-col justify-center">
              <div className="flex items-start gap-4 mb-4">
                <img
                  src={numberImage}
                  alt="Step number"
                  className="w-8 h-8 object-contain"
                />
                <h2 className={`text-3xl font-semibold ${textColorClass}`}>
                  {title}
                </h2>
              </div>
              <p className={`text-lg ${textColorClass}`}>{description}</p>
            </div>

            {/* Image content */}
            <div className="flex-1 flex justify-center items-center">
              <img
                src={contentImage}
                alt={`${title} illustration`}
                className="w-full max-w-lg object-contain rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProcessSteps: React.FC = () => {
  const steps: ProcessStepProps[] = [
    {
      numberImage: Number1,
      title: "Make Your First Impression Count",
      description:
        "Create a personalized profile that highlights your skills, experiences, and career aspirations. This is your chance to stand out and show recruiters exactly what makes you unique.",
      backgroundImage: ProcessBackground,
      contentImage: profileExample,
    },
    {
      numberImage: Number2,
      title: "Unlock Your True Potential",
      description:
        "Start your journey with the Test Center. Complete targeted assessments to reveal your strengths and growth areas. With clear insights, you can chart a course for success and retake the assessments to track your progress.",
      backgroundImage: RecruitmentBackground,
      contentImage: testCenterExample,
    },
    {
      numberImage: Number3,
      title: "Master New Skills and Stay Ahead in a Fast-Paced Tech World",
      description:
        "Knowledge is your edge. AlgoLink’s crash courses are built to help you tackle today’s challenges and prepare for tomorrow’s opportunities.",
      backgroundImage: ProcessBackground,
      contentImage: courseMapExample,
    },
    {
      numberImage: Number4,
      title: "Find the Right Job, Right Now",
      description:
        "AlgoLink’s smart job-matching system connects you with opportunities that align with your strengths and goals. You’ll be matched with roles that are the best fit, giving you a higher chance of success.",
      backgroundImage: RecruitmentBackground,
      contentImage: companyJobsExample,
    },
    {
      numberImage: Number5,
      title: "Stay In Control of Your Journey",
      description:
        "Track every step of your recruitment process with ease. Our progress tracker centralizes your applications, interviews, and recruiter feedback in one place. Plus, with the Messages feature, you can communicate directly with recruiters for timely updates, questions, and insights—keeping you proactive and in the loop at every stage.",
      backgroundImage: ProcessBackground,
      contentImage: messagesExample,
    },
  ];

  return (
    <div className="w-full">
      {steps.map((step) => (
        <ProcessStep key={step.numberImage} {...step} />
      ))}
    </div>
  );
};

export default ProcessSteps;
