import TopNavigation from "../home/TopNavigation";

const UseOfCookies: React.FC = () => {
  return (
    <>
      <div>
        <TopNavigation showMiddleNav={false} />
      </div>
      <div className="prose mx-auto my-4 container">
        <h1 className="text-white">Cookie Policy</h1>
        <p className="text-white">
          This is the Cookie Policy for AlgoLink, accessible from{" "}
          <a
            className="text-white"
            href="/use-of-cookies"
            target="_blank"
            rel="noreferrer"
          >
            www.algolink.net/use-of-cookies
          </a>
          .
        </p>
        <h2 className="text-white">What Are Cookies</h2>
        <p className="text-white">
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored. However this may downgrade or 'break'
          certain elements of the site's functionality.
        </p>
        <h2 className="text-white">How We Use Cookies</h2>
        <p className="text-white">
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <h2 className="text-white">Disabling Cookies</h2>
        <p className="text-white">
          If you have allowed us to set cookies on your device, and would like
          to take back this decision, you can adjust AlgoLink-specific cookie
          settings through your browser's Help tab. Here are additional
          instructions for major browsers:{" "}
          <a
            className="text-white"
            href="https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop"
          >
            Google
          </a>
          ,{" "}
          <a
            className="text-white"
            href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
          >
            Safari
          </a>
          ,{" "}
          <a
            className="text-white"
            href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox"
          >
            Firefox
          </a>
          .
        </p>
        <p className="text-white">
          Be aware that disabling cookies will affect the functionality of this
          and many other websites that you visit. Disabling cookies will usually
          result in also disabling certain functionality and features of the
          this site. Therefore it is recommended that you do not disable
          cookies.
        </p>
        <h2 className="text-white">The Cookies We Set</h2>
        <h3 className="text-white">Account related cookies</h3>
        <p className="text-white">
          If you create an account with us then we will use cookies for the
          management of the signup process and general administration. These
          cookies will usually be deleted when you log out however in some cases
          they may remain afterwards to remember your site preferences when
          logged out.
        </p>
        <h3 className="text-white">Login-related cookies</h3>
        <p className="text-white">
          We use cookies when you are logged in so that we can remember this
          fact. This prevents you from having to log in every single time you
          visit a new page. These cookies are typically removed or cleared when
          you log out to ensure that you can only access restricted features and
          areas when logged in.
        </p>
        <h3 className="text-white">Site preferences cookies</h3>
        <p className="text-white">
          In order to provide you with a great experience on this site we
          provide the functionality to set your preferences for how this site
          runs when you use it. In order to remember your preferences we need to
          set cookies so that this information can be called whenever you
          interact with a page is affected by your preferences.
        </p>
        <h2 className="text-white">Third Party Cookies</h2>
        <p className="text-white">
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>
        <ul>
          <li className="list-disc">
            <p className="text-white">
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
            </p>
            <p className="text-white">
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>
          </li>
        </ul>
        <h2 className="text-white">More Information</h2>
        <p className="text-white">
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
        <p className="text-white">
          For more general information on cookies, please read{" "}
          <a
            className="text-white"
            href="https://www.cookiepolicygenerator.com/sample-cookies-policy/"
          >
            this Cookies-Policy-related article
          </a>
          .
        </p>
        <p className="text-white">
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
        </p>
        <ul>
          <li className="list-disc">
            <p className="text-white">
              By emailing{" "}
              <strong className="text-white">
                support [at] algolink [dot] net
              </strong>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UseOfCookies;
