import React from "react";
import { Medal, ArrowRight } from "lucide-react";

interface TestOption {
  title: string;
  duration: string;
  route: string;
  icon: React.ComponentType<any>;
}

interface MergedCardProps {
  title: string;
  paragraphs: string[];
  testOptions: TestOption[];
}

const MergedInstructionsTestCard: React.FC<MergedCardProps> = ({
  title,
  paragraphs,
  testOptions,
}) => {
  const handleNavigate = (route: string) => {
    window.location.href = route;
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 pt-6 pl-6 pr-6">
      {/* Header with Icon */}
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 rounded-lg">
          <Medal className="w-7 h-7 text-blue-600 flex-shrink-0" />
        </div>
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
      </div>

      {/* Compact Instructions */}
      <div className="ml-16 mr-16 space-y-2 mb-2 text-md text-gray-600">
        {paragraphs.map((paragraph, idx) => (
          <p key={idx} className="leading-relaxed">
            {paragraph}
          </p>
        ))}
      </div>

      {/* Test Options Grid */}
      <div className="ml-36 mr-0 grid grid-cols-1 md:grid-cols-3 gap-16">
        {testOptions.map((test) => {
          const Icon = test.icon;
          return (
            <button
              key={test.title}
              onClick={() => handleNavigate(test.route)}
              className="group bg-white hover:bg-blue-50 p-4 rounded-lg transition-all duration-200"
            >
              <div className="flex items-center gap-3 mb-2">
                <div className="text-left">
                  <h3 className="font-medium text-gray-900 text-base">
                    {test.title}
                  </h3>
                  <span className="text-sm text-gray-500">{test.duration}</span>
                </div>
              </div>

              <div className="flex items-center text-md text-blue-600 font-medium">
                Start Test
                <ArrowRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MergedInstructionsTestCard;
