import { Link } from "react-router-dom";
import { Linkedin, Instagram, Twitter } from "lucide-react";
import LogoIcon from "../../assets/img/logos/white-logo-icon.png";

const Footer = () => {
  const socialLinks = [
    {
      icon: <Linkedin size={24} strokeWidth={1.5} />,
      href: "https://www.linkedin.com/company/algo-link/",
    },
    {
      icon: <Instagram size={24} strokeWidth={1.5} />,
      href: "https://instagram.com/algolinks",
    },
    {
      icon: <Twitter size={24} strokeWidth={1.5} />,
      href: "https://x.com/algolink",
    }, // need to make the X account
  ];

  return (
    <footer className="bg-[#0E172A] text-gray-300">
      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="grid grid-cols-4 gap-8">
          {/* Logo and Social Icons Section */}
          <div className="col-span-1">
            <Link to="/" className="flex items-center mb-10">
              <img src={LogoIcon} alt="AlgoLink" className="h-20 w-20" />
            </Link>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-10 w-10 bg-[#0E172A] border border-gray-300 rounded flex items-center justify-center hover:border-white hover:text-white transition-all duration-200 text-gray-300"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Contact Information */}
          <div className="col-span-1">
            <h3 className="text-white font-bold mb-4">Contact</h3>
            <div className="space-y-2">
              <p className="block text-gray-300 hover:text-white transition-colors duration-200 font-medium">
                support@algolink.net
              </p>
            </div>
          </div>

          {/* Enterprise and About Links */}
          <div className="col-span-1 grid grid-cols-2 gap-8">
            <div>
              <h3 className="text-white font-bold mb-4">About</h3>
              <div className="space-y-2">
                <Link
                  to="/terms-of-use"
                  style={{ textDecoration: "none" }}
                  className="block text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Terms of Use
                </Link>
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none" }}
                  className="block text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
