import React, {
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  FormEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axiosInterceptor from "../../utils/axiosInterceptor";
import sendVerificationCode from "../../utils/sendEmailVerification";

function Verify(): JSX.Element {
  const navigate = useNavigate();
  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [email, setEmail] = useState<string>("...");
  const [timeLastSent, setTimeLastSent] = useState<number>(
    new Date().getTime(),
  );
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());
  const isAuthenticated = !!localStorage.getItem("access_token");

  // Check if email is already verified
  useEffect(() => {
    const isEmailVerified = localStorage.getItem("is_email_verified");
    if (isEmailVerified === "true") {
      console.log("Already verified, redirecting to home");
      navigate("/");
    }
  }, [navigate]);

  // Timer effect to update currentTime every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const user_id = localStorage.getItem("user_id") || "";

  // Fetch user email
  useEffect(() => {
    if (!user_id) return;
    axiosInterceptor
      .get(`getuser/${user_id}/`)
      .then((response: any) => {
        setEmail(response.data["u_data"]["email"]);
      })
      .catch((error: any) => {
        console.error("Error fetching user email:", error);
      });
  }, [user_id]);

  // Handle changes in verification code inputs
  function handleCodeChange(index: number, value: string) {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  }

  // Handle key down events in verification code inputs
  function handleKeyDown(index: number, e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  }

  // Confirm sign-up by verifying the full code
  async function confirmSignUp(fullCode: string): Promise<boolean> {
    try {
      const response = await axiosInterceptor.get(
        `/check_verification_code/?code=${fullCode}&email=${email}`,
      );
      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem("is_email_verified", "true");
        alert("Account verified successfully!");
        setTimeout(() => {
          navigate("/account/submitted");
        }, 250);
        return true;
      }
    } catch (error) {
      console.error("Error verifying account:", error);
      alert("Invalid verification code. Please try again.");
    }
    return false;
  }

  // Handle form submission
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const fullCode = code.join("");
    confirmSignUp(fullCode).then((res) => {
      if (res) navigate("/");
    });
  }

  // Trigger resend code logic
  const triggerResendCodeLogic = async () => {
    await sendVerificationCode(email);
    setTimeLastSent(new Date().getTime());
    alert("Email resent! Please check your inbox.");
  };

  const secondsRemaining = Math.max(
    60 - Math.floor((currentTime - timeLastSent) / 1000),
    0,
  );

  const textColorClass = isAuthenticated ? "text-black" : "text-white";

  return (
    <div className="max-w-2xl flex flex-col mx-auto w-[370px] h-full justify-center -mt-8">
      <div className="shadow-sm border border-gray-300 rounded-lg p-4 flex flex-col gap-y-2">
        <h2 className={`text-2xl font-semibold ${textColorClass}`}>
          Account Verification
        </h2>
        <p className={`font-medium text-sm ${textColorClass}`}>
          A 6-digit verification code has been sent to {email}.
        </p>
        <Form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 items-center"
        >
          <div className="flex gap-2 my-3.5">
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <Form.Control
                key={index}
                ref={(el: HTMLInputElement) => {
                  inputRefs.current[index] = el;
                }}
                type="text"
                inputMode="numeric"
                pattern="[0-9]"
                maxLength={1}
                value={code[index]}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(index, e as KeyboardEvent<HTMLInputElement>)
                }
                className="w-10 h-10 text-center border-[1.5px] border-[#555555] focus:!border-[#1B4E99] focus:!shadow-[0_0_5px_rgba(27,78,153,0.5)] transition-all duration-200"
              />
            ))}
          </div>
          <Button
            type="submit"
            className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
          >
            Verify
          </Button>
        </Form>
        <p className={`text-sm font-medium text-center mt-1 ${textColorClass}`}>
          Didn't receive an email?{" "}
          <button
            onClick={triggerResendCodeLogic}
            className={
              "text-[#0059EC] font-medium hover:underline " +
              (email === "..." || new Date().getTime() - 60_000 < timeLastSent
                ? "!cursor-not-allowed"
                : "cursor-pointer")
            }
            disabled={
              email === "..." || new Date().getTime() - 60_000 < timeLastSent
            }
          >
            Resend
          </button>{" "}
          {secondsRemaining > 0 ? `(${secondsRemaining})` : ""}
        </p>
      </div>
    </div>
  );
}

export default Verify;
