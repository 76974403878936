// surveys/SurveyLink.tsx
import React from "react";
import { Link } from "react-router-dom";

export interface SurveyLinkProps {
  text: string;
  path: string;
}

// Named export for the link itself
export const SurveyLink: React.FC<SurveyLinkProps> = ({ text, path }) => {
  return (
    <Link
      to={path}
      className="p-1 rounded bg-blue-600 text-white text-center text-sm font-medium hover:bg-blue-700 w-40 no-underline"
    >
      {text}
    </Link>
  );
};

// Default export for the container
const SurveyLinkContainer: React.FC = () => {
  return (
    <div className="ml-4 flex justify-center gap-2">
      <SurveyLink text="Job Preferences" path="/job-preferences-survey" />
      <SurveyLink text="OCEAN Personality" path="/personality-survey" />
    </div>
  );
};

export default SurveyLinkContainer;

// import React from "react";
// import { Link } from "react-router-dom";

// interface SurveyLinkProps {
//   text: string;
//   path: string;
// }

// const SurveyLink: React.FC<SurveyLinkProps> = ({ text, path }) => {
//   return (
//     <Link
//       to={path}
//       className="p-1 rounded bg-blue-600 text-white text-center text-sm font-medium hover:bg-blue-700 w-40 no-underline"
//     >
//       {text}
//     </Link>
//   );
// };

// // Export a wrapper component that handles the side-by-side layout
// const SurveyLinkContainer: React.FC = () => {
//   return (
//     <div className="ml-4 flex justify-center gap-2">
//       <SurveyLink text="Job Preferences" path="/job-preferences-survey" />
//       <SurveyLink text="OCEAN Personality" path="/personality-survey" />
//     </div>
//   );
// };

// export default SurveyLinkContainer;
