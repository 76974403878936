// src/components/Modal.tsx

import React, { HTMLAttributes } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  setShow: (updatedShowState: boolean) => void;
  title: string;
}

const Modal: React.FC<ModalProps> = ({ show, setShow, title, children }) => {
  const handleClose = () => setShow(false);
  return (
    <BootstrapModal show={show} onHide={handleClose}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
