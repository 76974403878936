import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../routes/home/Header";
import axiosInterceptor from "../utils/axiosInterceptor";

const RootTemplate: React.FC = () => {
  const location = useLocation();
  const [isUserLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [pendingStateInfo, setPendingStateInfo] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      setUserLoggedIn(true);
    }
    setPendingStateInfo(false);

    if (
      window &&
      (window.location.pathname === "/" || window.location.pathname === "") &&
      window.location.hash
    ) {
      setTimeout(() => {
        const elementToScrollTo = document.querySelector(window.location.hash);
        elementToScrollTo?.scrollIntoView();
      }, 100);
    }
  }, []);

  const lastPolledForVerification = localStorage.getItem(
    "lastPolledForVerification",
  );
  const VERIFICATION_CHECKING_THRESHOLD = 1 * 60 * 1000; // 1 minute

  useEffect(() => {
    if (
      !lastPolledForVerification ||
      new Date().getTime() - parseInt(lastPolledForVerification) >
        VERIFICATION_CHECKING_THRESHOLD
    ) {
      const userId = localStorage.getItem("user_id");
      if (userId) {
        localStorage.setItem(
          "lastPolledForVerification",
          new Date().getTime().toString(),
        );
        axiosInterceptor
          .get(`/getuser/${userId}/`)
          .then((response) => {
            localStorage.setItem(
              "is_verified_by_algolink",
              response.data["u_data"]["is_verified_by_algolink"],
            );
            localStorage.setItem(
              "is_algolink_admin",
              response.data["u_data"]["is_algolink_admin"],
            );
            localStorage.setItem(
              "user_name",
              `${response.data["u_data"]["firstname"]} ${response.data["u_data"]["lastname"]}`,
            );
          })
          .catch((error) => {
            console.error("Error fetching user verification:", error);
          });
      }
    }
  }, [lastPolledForVerification]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return pendingStateInfo ? (
    <div className="flex justify-center w-full h-screen items-center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow overflow-hidden">
        {isUserLoggedIn && (
          <div className="h-full">
            <Header
              isUserLoggedIn={isUserLoggedIn}
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          </div>
        )}
        <div
          className={`flex-grow flex flex-col overflow-hidden transition-all duration-250 ease-out
            ${!isUserLoggedIn || window.location.pathname === "/account/pending-algolink-verification" ? "bg-gradient-radial from-[#101832] to-[#15171E]" : ""}
            ${isUserLoggedIn && window.location.pathname !== "/account/pending-algolink-verification" ? "pl-16" : ""}
            ${isUserLoggedIn && window.location.pathname !== "/account/pending-algolink-verification" && isSidebarOpen ? "pl-72" : ""}`}
        >
          <div
            className="flex-grow flex-col overflow-y-auto"
            id="scrollContainer"
            style={{
              backgroundColor: isUserLoggedIn ? "#ffffff" : undefined,
              background: !isUserLoggedIn
                ? "radial-gradient(circle, #101832 0%, #15171E 100%)"
                : undefined,
              padding: isUserLoggedIn ? "24px 40px 20px" : "0px",
              transition: "all 0.25s ease-out",
            }}
          >
            <Outlet />
            <div className="flex-grow"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootTemplate;
