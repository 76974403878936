import React, { useEffect, useState, FormEvent, ChangeEvent } from "react";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Link, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap"; // Import LinkContainer

interface EmailEntryFormProps {
  onSubmit: () => void;
}

const EmailEntryForm: React.FC<EmailEntryFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        await axiosInterceptor.post("/request_password_reset/", {
          email: email,
          tp: "consumer",
        });
        onSubmit();
      } catch (err: any) {
        if (
          err.response &&
          err.response.status === 404 &&
          err.response.data.error === "User with that email not found."
        ) {
          setEmail("");
          alert("No user found with that email.");
        } else {
          console.error(err.response);
          alert("An unknown error occurred — please try again later.");
        }
      }
    } else if (!validated) {
      setValidated(true);
    }
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4"
    >
      <h2 className="text-white text-2xl !font-normal text-left mb-3.5">
        Forgot Password
      </h2>
      <FloatingLabel label="Email">
        <Form.Control
          name="email"
          size="sm"
          type="email"
          placeholder=" "
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email.
        </Form.Control.Feedback>
      </FloatingLabel>
      <Button
        className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
        type="submit"
      >
        Submit
      </Button>
      <p className="text-center text-sm !font-normal mt-1.5">
        <span className="text-white">Remember your password? </span>
        <Link
          className="text-sm text-[#1B4E99] no-underline hover:text-[#1B4E99] hover:underline"
          to="/account/login"
        >
          Login
        </Link>
      </p>
    </Form>
  );
};

const EmailSentMessage: React.FC = () => {
  return (
    <div className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4 text-center">
      <h2 className="text-white text-2xl !font-normal mb-3.5">
        Forgot Password
      </h2>
      <p className="text-white font-light">
        A magic password reset link has been sent to your email.
      </p>
      <LinkContainer to="/account/login">
        <Button className="text-[#1B4E99] bg-white border-2 focus:text-[#1B4E99] hover:text-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-md shadow-sm mt-2.5">
          Back to Login
        </Button>
      </LinkContainer>
    </div>
  );
};

function Recover(): JSX.Element {
  const [step, setStep] = useState<number>(0);
  const [display, setDisplay] = useState<JSX.Element | null>(null);

  const nextStep = () => setStep(step + 1);

  useEffect(() => {
    switch (step) {
      case 0:
        setDisplay(<EmailEntryForm onSubmit={nextStep} />);
        break;
      case 1:
        setDisplay(<EmailSentMessage />);
        break;
      default:
        setDisplay(null);
    }
  }, [step]);

  return (
    <Container style={{ maxWidth: 500, padding: 10 }}>{display}</Container>
  );
}

export default Recover;
