import { FC, useMemo } from "react";
import { ProgressChipsProps } from "./types";

const ProgressChips: FC<ProgressChipsProps> = ({
  isLoaded,
  courses,
  coursesProgress,
  minified = false,
}) => {
  const [inProgress, notStarted, completed] = useMemo<
    [number, number, number]
  >(() => {
    let inProgress = 0;
    let notStarted = 0;
    let completed = 0;
    for (const course of courses) {
      if (
        coursesProgress[course.courseNumber] < 1 &&
        coursesProgress[course.courseNumber] > 0
      )
        inProgress++;
      else if (coursesProgress[course.courseNumber] <= 0) notStarted++;
      else completed++;
    }
    return [inProgress, notStarted, completed];
  }, [courses, coursesProgress]);

  return (
    <div className="flex flex-row gap-2">
      {isLoaded ? (
        <>
          <div className="h-6 min-w-6 bg-accent-positive-higher rounded-full justify-center items-center px-2">
            <p className="text-accent-positive-dimmer text-nowrap">
              {minified ? "" : "Completed: "}
              {completed}
            </p>
          </div>
          <div className="h-6 min-w-6 bg-accent-primary-higher rounded-full justify-center items-center px-2">
            <p className="text-accent-primary-dimmer text-nowrap">
              {minified ? "" : "In Progress: "}
              {inProgress}
            </p>
          </div>
          <div className="h-6 min-w-6 bg-background-dimmer rounded-full justify-center items-center px-2">
            <p className="text-foreground-dimmer text-nowrap">
              {minified ? "" : "Not Started: "}
              {notStarted}
            </p>
          </div>
        </>
      ) : (
        <div className="h-6 min-w-6 bg-background-dimmer rounded-full justify-center items-center px-2">
          <p className="text-foreground-dimmer text-nowrap">
            Loading Progress&hellip;
          </p>
        </div>
      )}
    </div>
  );
};

export default ProgressChips;
