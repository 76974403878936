import React from "react";
import { ConsolidatedJob, ConsolidatedCategory } from "./interfaces";

interface DynamicTabsProps {
  categories: ConsolidatedCategory[];
  categorizedJobs: Record<ConsolidatedCategory, ConsolidatedJob[]>;
  activeTab: ConsolidatedCategory;
  setActiveTab: (tab: ConsolidatedCategory) => void;
}

const DynamicTabs: React.FC<DynamicTabsProps> = ({
  categories,
  categorizedJobs,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="border-b border-gray-200 flex overflow-x-auto">
      {categories.map((category) => {
        const count = categorizedJobs[category].length;
        const isActive = activeTab === category;
        return (
          <button
            key={category}
            onClick={() => setActiveTab(category)}
            className={`relative whitespace-nowrap ml-2 px-14 py-3 text-base font-semibold transition-colors ${
              isActive ? "text-[#0059EC]" : "text-gray-500 hover:text-gray-700"
            }`}
          >
            <span className="flex items-center justify-center gap-3">
              {category}
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-base ${
                  isActive
                    ? "bg-blue-50 text-[#0059EC]"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                {count}
              </span>
            </span>
            {isActive && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default DynamicTabs;
