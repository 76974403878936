import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { ChecklistCardProps, ChecklistItem } from "./interfaces";
import { ListChecks } from "lucide-react";

const ChecklistCard: React.FC<ChecklistCardProps> = ({ userId }) => {
  const [items, setItems] = useState<ChecklistItem[]>([
    { id: 1, text: "Complete Profile Information", completed: false },
    { id: 2, text: "Upload Resume", completed: false },
    { id: 3, text: "Add Education History", completed: false },
    { id: 4, text: "Add Work Experience", completed: false },
    { id: 5, text: "Complete Job Preferences Survey", completed: false },
    { id: 6, text: "Complete Personality Survey", completed: false },
    { id: 7, text: "Add Profile Picture", completed: false },
    { id: 8, text: "Link Social Profiles", completed: false },
    { id: 9, text: "Complete Technical Assessment", completed: false },
    { id: 10, text: "Complete Behavioral Assessment", completed: false },
    { id: 11, text: "Complete Background Check", completed: false },
  ]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [assessmentCounts, setAssessmentCounts] = useState({
    technical_assessment_count: 0,
    behavioral_assessment_count: 0,
  });
  const [error, setError] = useState<string | null>(null);

  const toggleItem = async (id: number) => {
    try {
      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item,
      );
      setItems(updatedItems);

      await axiosInterceptor.post(`/update_checklist/${userId}/`, {
        checklist: updatedItems,
      });
    } catch (error) {
      console.error("Error updating checklist:", error);
    }
  };

  const updateAssessmentCounts = async () => {
    const updatedCounts = {
      technical_assessment_count: assessmentCounts.technical_assessment_count + 1,
      behavioral_assessment_count: assessmentCounts.behavioral_assessment_count + 1,
    };

    try {
      const response = await axiosInterceptor.post(`/assessment_stats/${userId}/`, {
        technical_assessment_count: updatedCounts.technical_assessment_count,
        behavioral_assessment_count: updatedCounts.behavioral_assessment_count,
      });

      if (response.data.success) {
        setAssessmentCounts(updatedCounts);
        setError(null);
      } else {
        setError(response.data.error || 'Error updating assessment stats');
        console.error('Error updating assessment stats:', response.data.error);
      }
    } catch (error) {
      setError('Error updating assessment stats');
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchChecklist = async () => {
      try {
        const response = await axiosInterceptor.get(`/get_checklist/${userId}/`);
        if (response.data?.checklist) {
          setItems(response.data.checklist);
        }
        const assessmentResponse = await axiosInterceptor.get(`/assessment_stats/${userId}/`);
        if (assessmentResponse.data?.stats) {
          setAssessmentCounts({
            technical_assessment_count: assessmentResponse.data.stats.technical_assessment_count || 0,
            behavioral_assessment_count: assessmentResponse.data.stats.behavioral_assessment_count || 0,
          });
        } else {
          setAssessmentCounts({
            technical_assessment_count: 0,
            behavioral_assessment_count: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching checklist:", error);
      }
    };

    fetchChecklist();
  }, [userId]);

  return (
    <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
      <div className="px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <ListChecks className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
            <h4 className="text-xl font-semibold text-gray-900">
              Profile Checklist
            </h4>
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-0 hover:bg-gray-50 rounded-lg transition-colors"
            aria-label={isExpanded ? "Collapse checklist" : "Expand checklist"}
          >
            <svg
              className={`w-6 h-6 text-gray-500 transform transition-transform ${
                isExpanded ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="ml-16 pl-1 pb-4 w-80">
        <div
          className={`space-y-3 overflow-hidden transition-all duration-300 ${
            isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          {items.map((item) => (
            <div
              key={item.id}
              className="flex items-center p-3 hover:bg-gray-50 rounded-lg border border-gray-200 cursor-pointer"
              onClick={() => toggleItem(item.id)}
            >
              <div className="flex items-center justify-center w-6 h-6 border-2 border-gray-300 rounded-lg mr-3 transition-colors">
                {item.completed && (
                  <svg
                    className="w-4 h-4 text-blue-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              <span
                className={`flex-1 ${
                  item.completed
                    ? "text-gray-500 line-through"
                    : "text-gray-900"
                }`}
              >
                {item.text}
              </span>
              {item.id === 9 && (
                <div className="text-gray-500 ml-2">
                  <span>{assessmentCounts.technical_assessment_count} Completions</span>
                </div>
              )}
              {item.id === 10 && (
                <div className="text-gray-500 ml-2">
                  <span>{assessmentCounts.behavioral_assessment_count} Completions</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {error && (
        <div className="px-4 py-2 text-red-500">
          <span>{error}</span>
        </div>
      )}

      {/* <div className="px-4 py-2">
        <button
          onClick={updateAssessmentCounts}
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Update Assessment Counts
        </button>
      </div> */}
    </div>
  );
};

export default ChecklistCard;
