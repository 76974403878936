// src/components/FormInput.tsx

import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

interface FormInputProps {
  name: string;
  label: string;
  type: string;
  value: string;
  handler: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  required?: boolean;
  placeholder?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  type,
  value,
  handler,
  required = false,
}) => {
  return (
    <FloatingLabel label={label}>
      <Form.Control
        name={name}
        type={type}
        required={required}
        value={value}
        onChange={handler}
      />
    </FloatingLabel>
  );
};

export default FormInput;

// import React, { useState } from "react";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
// import Form from "react-bootstrap/Form";

// export default function FormInput({
//   name,
//   label,
//   type,
//   value,
//   handler,
//   required,
// }) {
//   return (
//     <FloatingLabel label={label}>
//       <Form.Control
//         name={name}
//         type={type}
//         required={required ? true : false}
//         value={value}
//         onChange={handler}
//       />
//     </FloatingLabel>
//   );
// }
