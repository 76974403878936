import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/img/logos/white-logo-icon.png";
import SwapIcon from "../../assets/icons/swap-icon.png";

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
}

interface TopNavigationProps {
  showMiddleNav?: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({ href, children }) => (
  <a
    href={href}
    className="text-white/70 hover:text-white text-lg font-semibold transition-colors no-underline"
    onClick={(e) => {
      e.preventDefault();
      const element = document.querySelector(href);
      element?.scrollIntoView({ behavior: "smooth" });
    }}
  >
    {children}
  </a>
);

const TopNavigation: React.FC<TopNavigationProps> = ({
  showMiddleNav = false,
}) => {
  return (
    <nav className="relative z-50 w-[90%] ml-[5%] center px-10 py-3 flex items-center justify-between">
      {/* Logo */}
      <Link to="/" className="flex items-center no-underline">
        <img src={LogoIcon} alt="AlgoLink" className="h-24 w-24" />
      </Link>

      {/* Optional Middle Navigation */}
      {showMiddleNav && (
        <div className="flex items-center bg-[#1E293B]/40 backdrop-blur-sm rounded-full px-6 py-2 space-x-10 text-lg font-semibold">
          <NavLink href="#who-we-are">Who We Are</NavLink>
          <NavLink href="#getting-started">Getting Started</NavLink>
          <NavLink href="#testimonials">Testimonials</NavLink>
          <NavLink href="#faqs">FAQs</NavLink>

          {/* Referrals Toggle Button */}
          <Link
            to="https://enterprise.algolink.net"
            className="font-medium no-underline flex items-center space-x-0 px-0 py-2 text-[#94A3B8] hover:text-white transition-all duration-200"
          >
            <img src={SwapIcon} alt="Swap" className="w-10 h-10" />
            <span>Give Referrals</span>
          </Link>
        </div>
      )}

      {/* Authentication Buttons */}
      <div className="flex items-center space-x-3">
        <Link
          to="/account/login"
          className="text-sm font-medium no-underline px-4 py-1.5 border border-white/5 rounded-lg text-white hover:bg-white/5 transition-colors"
        >
          Log In
        </Link>
        <Link
          to="/account/signup"
          className="text-sm font-medium no-underline px-4 py-1.5 bg-[#2563EB] hover:bg-[#1D4ED8] text-white rounded-lg transition-colors"
        >
          Sign Up
        </Link>
      </div>
    </nav>
  );
};

export default TopNavigation;
