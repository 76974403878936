import {
  LectureContent,
  CourseContent,
  CourseProgress,
  EvaluatePrerequisiteResponse,
  PrerequisiteConnection,
  SavedCourseTypesResponse,
  LecturesProgress,
  Course,
  LectureContentTabs,
} from "./types";
import { API_BASE_URL } from "../../constants";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { LectureContentType } from "./types";

/**
 * Fetches a list of complete file paths from a data directory
 * @param {string} directoryPath The directory to fetch files from
 * @returns {Promise<string[]>} A promise that resolves into a list of strings representing the full file paths in the given directory
 *
 * @example
 * fetchFilePathsFromDirectory("course_materials/Computational-Functional-Genomics/file_store_1/lecture_notes_1")
 *
 * // output: Array [ "public/static/course_materials/Computational-Functional-Genomics/file_store_1/lecture_notes_1/lecture_notes_1.pdf" ]
 */
export const fetchFilePathsFromDirectory = async (
  directoryPath: string,
): Promise<string[]> => {
  try {
    const response = await axiosInterceptor.get(`/list_files/${directoryPath}`);
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.data.file_urls;
  } catch (error: unknown) {
    console.error(`Error fetching files from directory: ${error}`);
    return [];
  }
};

/**
 * Fetches a PNG file from the given url
 * @param {string} url Url to fetch image from
 * @throws Throws error if exception occurs while fetching data
 * @returns {Promise<Blob>} A promise that resolves into a blob containing the image data
 */
export const fetchPngFromUrl = async (url: string): Promise<Blob> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.blob();
  } catch (error: unknown) {
    console.error("Error fetching PNG:", error);
    throw error;
  }
};

/**
 * Fetches JSON data from the given url
 * @param {string} url Url to fetch data from
 * @throws Throws error if exception occurs while fetching data
 * @returns {Promise<any>} A promise that resolves into an unknown javascript object representing the json format
 */
export const fetchJsonData = async (url: string): Promise<any> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error: unknown) {
    console.error(`Error fetching JSON data:`, error);
    throw error;
  }
};

/**
 * Returns a list of all courses offered through Algolink
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<Course[]>} Full list of all courses on AlgoLink platform. Returns empty list if error occurs while fetching
 */
export const fetchAllCourses = async (): Promise<Course[]> => {
  try {
    const response = await axiosInterceptor.get("/api/serve_course_jsons/");
    const loadedCourses: Course[] = response.data.map((jsonData: any) => ({
      id: jsonData.courseName.replace(/ /g, "-"),
      name: jsonData.courseName,
      courseNumber: jsonData.courseNumber || "",
      courseType: jsonData.courseType,
      prerequisites: jsonData.prereqs || [],
    }));

    return loadedCourses;
  } catch (error: unknown) {
    console.error("Error fetching JSON files:", error);
    return [];
  }
};

/**
 * Returns a list of all (NEW) courses offered through Algolink
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<Course[]>} Full list of all courses on AlgoLink platform. Returns empty list if error occurs while fetching
 */
export const fetchAllNewCourses = async (): Promise<Course[]> => {
  try {
    const response = await axiosInterceptor.get("/api/new_serve_course_jsons/");
    const loadedCourses: Course[] = response.data.map((jsonData: any) => ({
      id: jsonData.name.replace(/ /g, "-"),
      name: jsonData.name,
      courseNumber: jsonData.course_number || "",
      courseType: jsonData.course_type,
      prerequisites: jsonData.prereqs || [],
    }));

    return loadedCourses;
  } catch (error: unknown) {
    console.error("Error fetching JSON files:", error);
    return [];
  }
};

/**
 * Finds the directory for a lecture given the list of directories that are nonempty
 * @param {string} lessonId A lesson's id
 * @param {string[][]} data A list of directories that are nonempty, with each sublist containing the filenames of files in that directory
 * @returns {{datastoreIndex: number, foundDirectory: string | null}} Returns an object containing the index of the data store containing the directory, and the directory name, if found
 */
export const findLectureDirectory = (
  lessonId: number,
  data: { filesInNonEmptyDirectories: string[][] },
): { datastoreIndex: number; foundDirectory: string | null } => {
  let totalItemsSeen = 0;
  let foundDirectory: string | null = null;
  let datastoreIndex = 1;

  for (const fileList of data.filesInNonEmptyDirectories) {
    if (
      lessonId >= totalItemsSeen &&
      lessonId < totalItemsSeen + fileList.length
    ) {
      const indexInList = lessonId - totalItemsSeen;
      fileList.sort((a, b) => {
        const numberA = parseInt(a.match(/(\d+)/)?.[0] || "0", 10);
        const numberB = parseInt(b.match(/(\d+)/)?.[0] || "0", 10);
        return numberA - numberB;
      });
      foundDirectory = fileList[indexInList];
      break;
    }
    totalItemsSeen += fileList.length;
    datastoreIndex += 1;
  }

  return { datastoreIndex, foundDirectory };
};

/**
 * Extracts the lecture type from a file obtained from AlgoLink's data store. Must be in the format [Lecture_Type_#].extension, with the lecture type being two words long
 * @param {string} fileName A file name
 * @returns {string} The lecture type of a given file
 * @example
 * extractLectureTypeFromFileName("lecture_videos_1") // returns: Lecture Videos
 * extractLectureTypeFromFileName("review_sheets.txt") // returns: Review Sheets
 */
export const extractLectureTypeFromFileName = (fileName: string): string => {
  const parts = fileName.split(/[_.]/);
  const key = parts
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return key;
};

/**
 * Extracts a number from the end of a file name
 * @param {string} fileName A file name
 * @returns {string | null} Returns a string of the number extracted, or null if no numbers match the description
 */
export const extractNumberFromFileName = (fileName: string): string | null => {
  const regex = /(\d+)$/;
  const match = regex.exec(fileName);
  return match ? match[1] : null;
};

/**
 * Fetches the data for a given courses
 * @param {string} courseId The ID for this course
 * @throws Will throw error if exception occurs while fetching data
 * @returns {Promise<CourseContent>} An object containing the data for this course
 */
export const fetchCourseContent = async (
  courseId: string,
): Promise<CourseContent> => {
  try {
    const id = courseId;
    const file = `${id}.json`;

    const dataObject = await fetch(
      `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/generated/${encodeURIComponent(
        file,
      )}`,
    );
    const data = await fetchJsonData(dataObject.url);

    const authorAndTermObject = await fetch(
      `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/citations/citations.json`,
    );
    const authorAndTerm = await fetchJsonData(authorAndTermObject.url);

    const content: CourseContent = {
      id: id,
      title: id.replace(/-/g, " "),
      author: authorAndTerm[id]["author"],
      term: authorAndTerm[id]["term"],
      contentGroups: [],
    };

    data.nonEmptyDirectories.forEach((_dir: any, i: number) => {
      const fileList = data.filesInNonEmptyDirectories[i] as string[];
      const key = extractLectureTypeFromFileName(fileList[0]);
      content.contentGroups.push({ contentType: key, contentList: fileList });
    });
    return content;
  } catch (error: unknown) {
    throw new Error(`Error fetching course Data: ${error}`);
  }
};

/**
 * Fetches the list of completed lectures for a course
 * @param {string} courseId The ID of this course
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns A list of all completed lectures for a given course, or an empty list if an error occurs while fetching
 */
export const fetchCompletedLectures = async (
  courseId: string,
): Promise<string[]> => {
  try {
    const response = await axiosInterceptor.get(
      `/get-completed-lectures-for-course/${courseId}/`,
    );
    const completedLectures = response.data.map(
      (lecture: any) => lecture.lecture,
    );
    return completedLectures;
  } catch (error) {
    console.error("Error fetching completed lectures:", error);
    return [];
  }
};

/**
 * Toggles the completion status of the specified lecture
 *  requires: courseId and lectureId must correspond to real lectures
 * @param {string} courseId The course that this lecture belongs to
 * @param {string} lectureId The ID of this lecture
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<string[]>} The updated list of completed lectures, or an empty list if an error occurs while fetching
 */
export const toggleLectureCompletion = async (
  courseId: string,
  lectureId: string,
): Promise<string[]> => {
  try {
    await axiosInterceptor.post(
      `/toggle-lecture-completion-for-course/${courseId}/`,
      { lecture: lectureId },
    );
    const completedLectures = await fetchCompletedLectures(courseId);
    return completedLectures;
  } catch (error: unknown) {
    console.error("Error while toggling lecture completion: ", error);
    return [];
  }
};

/**
 * Retrieves an object containing the data (url, content type) for a given lecture in a course
 * @param {string} courseId The ID for a course
 * @param {number} lectureNumber A lecture number within the course
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<LectureContent>} A promise that resolves into the content for a given lecture
 */
export const fetchLectureContent = async (
  courseId: string,
  lectureNumber: number,
): Promise<LectureContent> => {
  const fileUrl = `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/generated/${encodeURIComponent(
    courseId,
  )}.json`;

  try {
    const data = await fetch(fileUrl).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    });

    const result = await findLectureDirectory(lectureNumber, data);
    const foundDirectory = result.foundDirectory;
    const datastoreIndex = result.datastoreIndex;

    const mainDirectoryPath = `course_materials/${courseId}/file_store_${datastoreIndex}/${foundDirectory}`;
    const mainFilesList = await fetchFilePathsFromDirectory(mainDirectoryPath);

    const supportDirectoryPath = `course_materials/${courseId}/support_file_store_${datastoreIndex}/${foundDirectory}`;
    const supportFilesList =
      await fetchFilePathsFromDirectory(supportDirectoryPath);

    const supportFilesUrls = supportFilesList.map(
      (supportFile) =>
        `${API_BASE_URL}/serve_file/${supportFile.replace(/^\/static\//, "")}`,
    );

    const isPdf = mainFilesList[0]?.endsWith(".pdf");
    const isMp4 = mainFilesList[0]?.endsWith(".mp4");

    const tabs: LectureContentTabs[] = [
      { name: "Content", description: [] },
      { name: "Resources", description: supportFilesUrls },
      { name: "Upload", description: [courseId] },
      { name: "Discussion", description: [courseId] },
    ];

    if (isPdf) {
      const content: LectureContent = {
        courseId: courseId,
        type: LectureContentType.PDF,
        mainFilesList,
        tabs,
      };
      return content;
    } else if (isMp4) {
      const content: LectureContent = {
        courseId: courseId,
        type: LectureContentType.VIDEO,
        mainFilesList,
        tabs,
      };

      return content;
    } else {
      return {
        courseId: courseId,
        type: LectureContentType.UNSUPPORTED,
        mainFilesList: [],
        tabs: [],
      };
    }
  } catch (error) {
    console.error("Error fetching or processing JSON:", error);
    throw error;
  }
};

/**
 * Fetches all course progress data for a user's courses
 * @param {string} userId The userId for the current logged in user
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<CourseProgress[]>} A promise that resolves into a list of CourseProgress objects, or an empty list if an error occurs while fetching data
 */
export const fetchCoursesProgress = async (
  userId: string,
): Promise<CourseProgress[]> => {
  try {
    const response = await axiosInterceptor.get<CourseProgress[]>(
      `/course-progress/${userId}/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const progressData = response.data;
    return progressData;
  } catch (error: unknown) {
    console.error("Error fetching courses' progress", error);
    return [];
  }
};

/**
 * Fetches a list of saved course type filters based on autosaved preferences from the user's last session
 * @param {string} userId The userId for the current logged in user
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<string[]>} A promise that resolves into a list of save course filter types, or an empty list if an error occurs while fetching data
 */
export const fetchSavedCourseTypes = async (
  userId: string,
): Promise<string[]> => {
  try {
    const response = await axiosInterceptor.get<SavedCourseTypesResponse>(
      `/get_saved_course_types_for_user/${userId}/`,
    );
    return response.data.saved_course_types;
  } catch (error: unknown) {
    console.error("Error fetching saved course types", error);
    return [];
  }
};

/**
 * Finds all the prerequisites for a given course recursively
 * @param {string} courseNumber Course number to search from
 * @param {Course[]} allCourses A list of all courses offered by AlgoLink
 * requires: courses contains all prerequisites for a given course.
 * @throws {Error} Will throw error if it cannot find a corresponding course for a course number in allCourses
 * @returns {Set<string>} Set containing the course numbers of all prerequisites of given course, includes specified course
 */
export const findRecursiveCoursePrerequisites = (
  courseNumber: string,
  allCourses: Course[],
): Set<string> => {
  const coursePrerequisites: Set<string> = new Set();
  const searchPrerequisitesRecursively = (
    currentCourseNumber: string,
    countedCoursePrerequisites: Set<string>,
  ) => {
    if (!countedCoursePrerequisites.has(currentCourseNumber)) {
      countedCoursePrerequisites.add(currentCourseNumber);
      const currentCourse = allCourses.find(
        (course) => course.courseNumber === currentCourseNumber,
      );
      if (!currentCourse) {
        throw new Error(
          `course with course number of ${currentCourseNumber} does not exist`,
        );
      }

      if (currentCourse.prerequisites) {
        currentCourse.prerequisites.forEach((prerequisiteCourseNumber) => {
          searchPrerequisitesRecursively(
            prerequisiteCourseNumber,
            countedCoursePrerequisites,
          );
        });
      }
    }
  };
  searchPrerequisitesRecursively(courseNumber, coursePrerequisites);
  return coursePrerequisites;
};

/**
 * Computes the level (max prerequisite depth) of a every course in a given list
 * @param {Course[]} allCourses A list of all courses
 * @returns {[Record<string, number>], PrerequisiteConnection[]]} A tuple with the first element being a map of each course's course number to its level and the
 *  second element being a list of course -> prerequisite relationships
 */
export const getCourseLevels = (
  allCourses: Course[],
): [Record<string, number>, PrerequisiteConnection[]] => {
  const levels: Record<string, number> = {};
  const prerequisiteConnections: PrerequisiteConnection[] = [];

  const getCourseLevel = (
    course: Course,
    levelRecord: Record<string, number>,
    prerequisiteConnections: PrerequisiteConnection[],
  ): number => {
    if (levelRecord[course.courseNumber] !== undefined) {
      return levelRecord[course.courseNumber];
    }
    if (!course.prerequisites || course.prerequisites.length === 0) {
      levelRecord[course.courseNumber] = 0;
      return 0;
    }

    const prereqLevels = course.prerequisites.map((prerequisite) => {
      const prerequisiteCourse = allCourses.find(
        (c) => c.courseNumber === prerequisite,
      );
      if (prerequisiteCourse) {
        prerequisiteConnections.push({
          course: course,
          prerequisite: prerequisiteCourse,
        });
        return getCourseLevel(
          prerequisiteCourse,
          levelRecord,
          prerequisiteConnections,
        );
      } else return -1;
    });
    const level = Math.max(...prereqLevels) + 1;
    levelRecord[course.courseNumber] = level;
    return level;
  };

  allCourses.forEach((course) =>
    getCourseLevel(course, levels, prerequisiteConnections),
  );

  return [levels, prerequisiteConnections];
};

/**
 * Takes the current courses' progress and computes a list of courses that are mastered
 * @param {Course[]} allCourses A list of all courses offered through AlgoLink
 * @param {CourseProgress[]} progressData A list of {course number, progress} pairs, with progress being [0, 1]
 * @returns {Course[]} A list of courses that are mastered
 */
export const computeMasteredCourses = (
  allCourses: Course[],
  progressData: CourseProgress[],
): Course[] => {
  const masteredCourseNumbers = progressData
    .filter((course) => course.progress >= 1.0)
    .map((course) => course.courseNumber);
  return allCourses.filter((course) =>
    masteredCourseNumbers.includes(course.courseNumber),
  );
};

/**
 * Computes whether a course is missing a prerequisite
 * @param {Course} course A course offered by AlgoLink
 * @param {CourseProgress[]} progressData A list of {course number, progress} pairs, with progress being [0, 1]
 * @returns {boolean} True if one of the course's prerequisites is incomplete
 */
export const isCourseMissingPrerequisites = (
  course: Course,
  progressData: CourseProgress[],
) => {
  if (course.prerequisites) {
    const containsIncompletePrerequisite = course.prerequisites.some(
      (prerequisiteCourseNumber) => {
        const incompletePrerequisite = progressData.find(
          (courseProgress) =>
            courseProgress.courseNumber === prerequisiteCourseNumber &&
            courseProgress.progress < 1,
        );
        if (incompletePrerequisite !== undefined) return true;
        return false;
      },
    );
    return containsIncompletePrerequisite;
  } else return false;
};

/**
 * Takes the current courses' progress and computes a list of courses missing prerequisites
 * This is a non-recursive function, meaning that courses whose prerequisites have incomplete children but are mastered will not count as missing a prerequisite
 * @param {Course[]} allCourses A list of all courses offered through AlgoLink
 * @param {CourseProgress[]} progressData a list of {course number, progress} pairs, with progress being [0, 1]
 * @returns {Course[]} A list of courses that have incomplete prerequisites
 */
export const computeCoursesMissingPrerequisites = (
  allCourses: Course[],
  progressData: CourseProgress[],
): Course[] => {
  return allCourses.filter((course) => {
    if (course.prerequisites)
      return isCourseMissingPrerequisites(course, progressData);
    else return false;
  });
};

/**
 * Takes the current courses' progress and fetches a list of recommended courses using AlgoLink's recommendation API
 * @param {Course[]} allCourses A list of all courses offered through AlgoLink
 * @param {CourseProgress[]} progressData A list of {course number, progress} pairs, with progress being [0, 1]
 *  requires: every course described in progressData must also be present in allCourses
 * @throws Does not throw an error, but will log any exceptions that occur while fetching data
 * @returns {Promise<Course[]>} Returns a list of recommended courses to take, or an empty list if an error occurs while fetching recommendations.
 */
export const fetchCourseRecommendations = async (
  allCourses: Course[],
  progressData: CourseProgress[],
): Promise<Course[]> => {
  const masteredCourseNumbers = computeMasteredCourses(
    allCourses,
    progressData,
  ).map((course) => course.courseNumber);

  try {
    const response = await axiosInterceptor.post<EvaluatePrerequisiteResponse>(
      `/api/evaluate-prerequisites`,
      { masteredCourses: masteredCourseNumbers },
      { headers: { "Content-Type": "application/json" } },
    );

    const data = response.data;
    return data.recommendations
      .map((courseNum) =>
        allCourses.find((course) => course.courseNumber === courseNum),
      )
      .filter((course): course is Course => course != null);
  } catch (error: unknown) {
    console.error("Error while fetching course recommendations: ", error);
    return [];
  }
};

/**
 * generates a citation for an MIT OpenCourseWare course
 * @param {string} author the author of the MIT OCW course
 * @param {string} title the name of the MIT OCW course
 * @param {string} term the term that the OCW course was offered
 * @returns {string} a citation for an MIT OCW course given the specified information
 */
export const generateMITCitation = (
  author?: string,
  title?: string,
  term?: string,
): string => {
  return `${author ? `${author}. ` : ""}${title ? `${title}. ` : ""}${
    term ? `${term}. ` : ""
  }Massachusetts Institute of Technology: MIT OpenCourseWare, https://ocw.mit.edu/. License: Creative Commons BY-NC-SA.`;
};

/**
 * extracts the URL of the file corresponding to a given lecture's content
 * @param {LectureContent} lectureContent the content for the target lecture
 * @returns {string} the url corresponding to this lecture's files
 */
export const extractLectureContentURL = (
  lectureContent: LectureContent,
): string => {
  const s3BaseUrl =
    "https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/";
  const encodedFilePath = encodeURIComponent(
    lectureContent.mainFilesList[0],
  ).replace(/%2F/g, "/");
  const url = `${s3BaseUrl}${encodedFilePath}`;
  return url;
};

/**
 * Extracts lecture name from file path for a lecture's content, i.e /User/ABC/Documents/course_notes_1.txt => Course Notes 1
 * @param {string} filePath an absolute or relative file path to a file
 * @returns {string} the extracted name, or an empty string if path is not a file
 */
export const extractLectureNameFromFilePath = (filePath: string): string => {
  const fileName = filePath.split("/").pop() || "";

  const nameWithoutExtension = fileName.split(".").slice(0, -1).join(".");
  return nameWithoutExtension
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Computes the progress for all lectures in a course
 * @param {CourseContent} courseContent the course content containing LectureGroups
 * @param {string[]} completedLectures a list of file names in the format lecture_group_#
 * @returns {LecturesProgress[]} a list of lectures progress that each represents the progress for one lecture type
 */
export const computeLecturesProgress = (
  courseContent: CourseContent,
  completedLectures: string[],
): LecturesProgress[] => {
  const computedLecturesProgressList: LecturesProgress[] | null =
    courseContent.contentGroups.map((currentCourseContent) => {
      const currentCompletedLectures = currentCourseContent.contentList.filter(
        (lectureId) => completedLectures.includes(lectureId),
      );
      return {
        lectureType: currentCourseContent.contentType,
        progress:
          currentCompletedLectures.length /
          currentCourseContent.contentList.length,
        totalLectures: currentCourseContent.contentList.length,
      };
    });
  return computedLecturesProgressList;
};

/**
 * Fetches the userID from localstorage
 * @throws throws error when user is not logged in
 * @returns {string} the userID
 */
export const fetchUserId = () => {
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    throw new Error("User is not logged in");
  }
  return userId;
};
