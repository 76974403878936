import {
  // Technical & Skills Icons
  Cpu, // AI/CS/Software
  Wrench, // Hardware/Tools
  ChartLine, // Finance/Economics
  Microscope, // Science/Bio/Chem

  // Background & Career Icons
  Building2, // Company/Industry
  Briefcase, // Work Experience
  BadgeCheck, // Job/Experience
  GraduationCap, // Education
  Trophy, // Skills/Achievements

  // Behavioral Icons
  Users, // Management/Team
  Brain, // Intelligence/Cognitive
  Shield, // Security/Resilience
  Heart, // Culture/Values

  // Profile Icons
  User, // Personal Info
  Mail, // Contact
  Link, // Links/Social
  FileText, // Documents/Resume

  // Document Icons
  FileSpreadsheet, // Transcript
  ClipboardCheck, // Tests
  Code, // Programming
  CircuitBoard, // Technical test
  Layers, // Background test
  Smile, // Behavioral test

  // Course Category Icons
  Binary, // Theoretical CS
  ComputerIcon, // Computer Engineering (switched from Cpu)
  HeartPulse, // Computational Biology
  BarChart2, // Probability & Statistics
  Sparkles, // AI (switched from Brain)
  Code2, // Computer Science
  Target, // Optimization
  LineChart, // Mathematical Finance
  Boxes, // Foundational Math
  BookCheck,
  BookUser,
  MonitorCog,
  BookA, // Interview Prep (switched from GraduationCap)
} from "lucide-react";
import { CourseCategory } from "./routes/courses/types";
import { LucideIcon } from "lucide-react";

// Icon mapping for TestCenter components
export const testCenterIcons = {
  technical: {
    group1: Cpu,
    group2: Wrench,
    group3: ChartLine,
    group4: Microscope,
  },
  behavioral: {
    management: Users,
    expression: Brain,
    resilience: Shield,
    culture: Heart,
  },
  background: {
    industries: Building2,
    jobTitles: BadgeCheck,
    skills: Trophy,
  },
  // New section for test option icons
  testOptions: {
    technical: CircuitBoard,
    background: Layers,
    behavioral: Smile,
  },
};

export const profileIcons = {
  header: {
    personal: User,
    contact: Mail,
    links: Link,
  },
  career: {
    education: GraduationCap,
    experience: Briefcase,
    courses: BookA,
    skills: Code,
    resume: FileText,
    transcript: FileSpreadsheet,
    tests: ClipboardCheck,
    personality: BookUser, // New: Personality Icon
    jobPreferences: MonitorCog, // New: Job Preferences Icon
  },
};

// Then use this type in our courseIcons definition
export const courseIcons: Record<CourseCategory, LucideIcon> = {
  "Theoretical Computer Science": Binary,
  "Computer Engineering": ComputerIcon,
  "Computational Biology": HeartPulse,
  "Probability & Statistics": BarChart2,
  "Artificial Intelligence": Sparkles,
  "Computer Science": Code2,
  Optimization: Target,
  "Mathematical Finance": LineChart,
  "Foundational Math": Boxes,
  "Interview Preparation": BookCheck,
};

export default {
  testCenterIcons,
  profileIcons,
};
