import { HTMLAttributes } from "react";

interface HorizontalSelectionProps {
  isSelected: boolean;
  handleSelectedChange: (updatedSelectedState: boolean) => void;
}

/**
 * @component HorizontalSelection
 * @param {boolean} props.isSelected a boolean indicating whether this horizontal selection is selected
 * @param {Function} props.handleSelectedChange a callback that takes a single boolean, which represents the updated selection state, as a parameter
 * @param {ReactNode[]} props.children a list of child reactNodes meant to be rendered within the selection
 * @returns {ReactElement} a horizontal selection box that turns blue when toggled/selected
 */
const HorizontalSelection = ({
  isSelected,
  handleSelectedChange,
  children,
}: HorizontalSelectionProps & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      role="switch"
      aria-checked={isSelected}
      onClick={() => handleSelectedChange(!isSelected)}
      className={`w-full px-4 py-3 transition-colors duration-200 border-[1px] border-solid border-background-dimmest rounded-md hover:bg-background-dimmer
      ${isSelected ? "bg-accent-primary-higher" : "bg-background"}
        `}
    >
      <div className="flex gap-4 items-center justify-start">
        <div
          className={`relative shrink-0 w-5 h-5 border-[1px] rounded-sm 
          ${
            isSelected
              ? "border-accent-primary bg-accent-primary"
              : "border-background-deepest"
          }`}
        >
          {isSelected && (
            <svg
              className="absolute inset-0 text-background"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>

        {children}
      </div>
    </button>
  );
};

export default HorizontalSelection;
