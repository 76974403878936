import React from "react";
import { PersonalityScoreCardProps } from "../interfaces";

const PersonalityScoreCard: React.FC<PersonalityScoreCardProps> = ({
  scores,
}) => {
  if (!scores) {
    return (
      <div className="w-full">
        <h3 className="mb-4">Personality Profile</h3>
        <p className="text-gray-600">
          No personality scores available. Click the "OCEAN Personality" survey
          link below to complete your assessment.
        </p>
      </div>
    );
  }

  const traits = [
    { key: "O", name: "Openness", score: scores.O },
    { key: "C", name: "Conscientiousness", score: scores.C },
    { key: "E", name: "Extraversion", score: scores.E },
    { key: "A", name: "Agreeableness", score: scores.A },
    { key: "N", name: "Neuroticism", score: scores.N },
  ];

  return (
    <div className="mt-4 ml-5 w-80">
      <div className="space-y-4">
        {traits.map(({ key, name, score }) => (
          <div key={key} className="flex flex-col">
            <div className="flex justify-between mb-1">
              <span className="text-sm font-medium text-gray-700">{name}</span>
              <span className="text-sm font-medium text-gray-700">
                {score}/40
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full"
                style={{ width: `${(score / 40) * 100}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalityScoreCard;
