import React from "react";

interface VideoCardProps {
  videoUrl?: string;
  title: string;
}
// In VideoCard.tsx
export const VideoCard: React.FC<VideoCardProps> = ({ videoUrl, title }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleClick = () => {
    if (videoUrl) {
      // If there's a real URL, let the user play it:
      setIsPlaying(true);
    } else {
      // Or maybe show an alert that says "No video available"
      alert("Sorry, no video available for this segment!");
    }
  };

  if (!isPlaying) {
    return (
      <div
        onClick={handleClick}
        className="relative w-full h-[60vh] bg-gray-200 flex items-center justify-center mb-4 rounded cursor-pointer"
      >
        <div className="flex flex-col items-center">
          <p className="text-sm font-semibold">{title}</p>
          <p className="text-xs text-gray-600">Click to play</p>
        </div>
        {/* Fake 'Play' icon in center: */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-12 h-12 bg-black opacity-70 rounded-full flex items-center justify-center">
            <svg
              className="w-6 h-6 text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6.3 4.16a1 1 0 0 1 1.66-.76l7.2 5.84a1 1 0 0 1 0 1.52l-7.2 5.84A1 1 0 0 1 6 16.84V4.16z" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  // If isPlaying is true and we have a real URL, show the HTML video or an iframe
  return videoUrl ? (
    <div className="w-full mb-4">
      <p className="text-sm font-semibold mb-1">{title}</p>
      <video
        src={videoUrl}
        autoPlay
        controls
        className="w-full h-48 bg-black rounded"
      />
    </div>
  ) : (
    // fallback if user tries to play but there's no URL
    <div className="w-full h-48 bg-gray-200 flex items-center justify-center mb-4 rounded">
      <p className="text-sm text-gray-600">No Video Available</p>
    </div>
  );
};
