import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";
import { Dropdown } from "react-bootstrap";
import {
  Course,
  CourseProgress,
  SelectedCourseDrawerProps,
  CourseContent,
  LectureContent,
  LecturesProgress,
} from "./types";
import SelectedCourseDrawer from "./SelectedCourseDrawer";
import {
  fetchCourseRecommendations,
  fetchCoursesProgress,
  fetchSavedCourseTypes,
  fetchAllCourses,
  fetchCourseContent,
  fetchLectureContent,
  isCourseMissingPrerequisites,
  fetchCompletedLectures,
  generateMITCitation,
  extractLectureContentURL,
  computeLecturesProgress,
  fetchUserId,
} from "./courseUtils";
import { useNavigate } from "react-router-dom";
import FileURLViewer from "./FileUrlViewer";
import CourseMap from "./CourseMap";
import CoursePaths from "./CoursePaths";
import SegmentedControl from "../../components/SegmentedControl";
import { DropdownToggle } from "../../components/DropdownToggle";
import ProgressChips from "./ProgressChips";

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  const [recommendations, setRecommendations] = useState<Course[]>([]);
  const [coursesProgress, setCoursesProgress] = useState<CourseProgress[]>([]);
  const [selectedCourseTypes, setSelectedCourseTypes] = useState<
    Record<string, boolean>
  >({});
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [lastSelectedCourse, setLastSelectedCourse] = useState<Course | null>(
    null,
  );
  const [focusedCourse, setFocusedCourse] = useState<Course | null>(null);
  const [courseDataLoaded, setCourseDataLoaded] = useState<boolean>(false);
  const [progressDataLoaded, setProgressDataLoaded] = useState<boolean>(false);
  const [recommendationDataLoaded, setRecommendationDataLoaded] =
    useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>("Course Pathways");

  const navigate = useNavigate();
  const handleNavigateToCourse = useCallback(
    (course: Course) => {
      navigate(`/courses/${course.id}/lectures/1`);
    },
    [navigate],
  );

  const [selectedCourseContent, setSelectedCourseContent] =
    useState<CourseContent | null>(null);
  const [
    selectedCourseLecturePreviewData,
    setSelectedCourseLecturePreviewData,
  ] = useState<LectureContent | null>(null);
  const [selectedCourseCompletedLectures, setSelectedCourseCompletedLectures] =
    useState<string[] | null>(null);

  const [showSelectedCourseDrawer, setShowSelectedCourseDrawer] =
    useState<boolean>(false);

  useEffect(() => {
    const loadAllData = async () => {
      const loadCourses = async (): Promise<Course[]> => {
        const loadedCourses = await fetchAllCourses();
        setCourses(loadedCourses);
        setCourseDataLoaded(true);
        return loadedCourses;
      };

      const loadProgress = async (): Promise<CourseProgress[]> => {
        const userId = fetchUserId();
        const loadedProgress = await fetchCoursesProgress(userId);
        setCoursesProgress(loadedProgress);
        setProgressDataLoaded(true);
        return loadedProgress;
      };
      const [allCourses, allCoursesProgress] = await Promise.all([
        loadCourses(),
        loadProgress(),
      ]);
      const loadedRecommendations = await fetchCourseRecommendations(
        allCourses,
        allCoursesProgress,
      );
      setRecommendations(loadedRecommendations);
      setRecommendationDataLoaded(true);
    };
    try {
      loadAllData();
    } catch (error: unknown) {
      navigate("/");
      console.error(error);
    }
  }, [navigate]);

  const loadSavedCourseTypes = async (loadedCourses: Course[]) => {
    const courseTypes = [
      ...new Set(loadedCourses.map((course) => course.courseType)),
    ];
    try {
      const user_id = localStorage.getItem("user_id");
      if (!user_id) throw new Error("User ID is invalid, or does not exist");

      const savedTypes = await fetchSavedCourseTypes(user_id);
      const selectedTypes = courseTypes.reduce(
        (acc, type) => ({ ...acc, [type]: savedTypes.includes(type) }),
        {} as Record<string, boolean>,
      );

      if (Object.values(selectedTypes).every((value) => !value)) {
        // If no saved types are true, default all to true
        setSelectedCourseTypes(
          courseTypes.reduce(
            (acc, type) => ({ ...acc, [type]: true }),
            {} as Record<string, boolean>,
          ),
        );
      } else {
        setSelectedCourseTypes(selectedTypes);
      }
    } catch (error) {
      console.error("Error loading saved course types:", error);
      const selectedTypes = courseTypes.reduce(
        (acc, type) => ({ ...acc, [type]: true }),
        {} as Record<string, boolean>,
      );
      setSelectedCourseTypes(selectedTypes);
    }
  };

  useEffect(() => {
    loadSavedCourseTypes(courses);
  }, [courses]);

  /**
   * updates the selected course types by toggling whether or not the specified type is included
   * @param type the course type that was either activated or deactivated
   */
  const handleCourseTypeChange = async (type: string) => {
    const newSelectedTypes = {
      ...selectedCourseTypes,
      [type]: !selectedCourseTypes[type],
    };
    setSelectedCourseTypes(newSelectedTypes);

    const user_id = localStorage.getItem("user_id");
    const savedTypes = Object.entries(newSelectedTypes)
      .filter(([, isSelected]) => isSelected)
      .map(([courseType]) => courseType);

    try {
      await axiosInterceptor.post(
        `/set_saved_course_types_for_user/${user_id}/`,
        { saved_course_types: savedTypes },
      );
    } catch (error) {
      console.error("Error saving course types:", error);
    }
  };

  /**
   * handles when a course card is clicked
   * @param course selects a course and computes all required prerequisites
   */
  const handleCourseSelectedChange = (course: Course | null) => {
    setSelectedCourse(course);
    setFocusedCourse(course);
    if (!course) {
      setShowSelectedCourseDrawer(false);
    }
  };

  useEffect(() => {
    const updateCourseDrawerData = async () => {
      if (selectedCourse) {
        setLastSelectedCourse(selectedCourse);
        setShowSelectedCourseDrawer(true);
        setSelectedCourseContent(await fetchCourseContent(selectedCourse.id));
        setSelectedCourseCompletedLectures(
          await fetchCompletedLectures(selectedCourse.id),
        );
        setSelectedCourseLecturePreviewData(
          await fetchLectureContent(selectedCourse.id, 0),
        );
      }
    };
    updateCourseDrawerData();
  }, [selectedCourse]);

  const selectedCourseDrawerProps =
    useMemo<SelectedCourseDrawerProps | null>(() => {
      if (lastSelectedCourse) {
        if (
          lastSelectedCourse &&
          selectedCourseContent &&
          selectedCourseCompletedLectures
        ) {
          const selectedCourseProgress = coursesProgress.find(
            (courseProgress) =>
              courseProgress.courseNumber === lastSelectedCourse.courseNumber,
          );
          const prerequisites = courses.filter((currentCourse) =>
            lastSelectedCourse.prerequisites
              ? lastSelectedCourse.prerequisites.includes(
                  currentCourse.courseNumber,
                )
              : false,
          );
          const recommended = recommendations.some(
            (recommendedCourse) =>
              lastSelectedCourse.courseNumber ===
              recommendedCourse.courseNumber,
          );
          const missingPrerequisites = isCourseMissingPrerequisites(
            lastSelectedCourse,
            coursesProgress,
          );
          const citation = generateMITCitation(
            selectedCourseContent.author,
            selectedCourseContent.title,
            selectedCourseContent.term,
          );
          const computedLecturesProgressList: LecturesProgress[] | null =
            computeLecturesProgress(
              selectedCourseContent,
              selectedCourseCompletedLectures,
            );

          const lecturePreviewURL = selectedCourseLecturePreviewData
            ? extractLectureContentURL(selectedCourseLecturePreviewData)
            : null;
          return {
            showDrawer: showSelectedCourseDrawer,
            selectedCourse: lastSelectedCourse,
            selectedCoursePrerequisites: prerequisites,
            selectedCourseCitation: citation,
            courseStatusChipProps:
              selectedCourseProgress !== undefined
                ? {
                    courseProgress: selectedCourseProgress.progress,
                    isRecommended: recommended,
                    needsMorePrerequisites: missingPrerequisites,
                  }
                : null,
            lecturePreview:
              lecturePreviewURL !== null && selectedCourseLecturePreviewData ? (
                <FileURLViewer
                  url={lecturePreviewURL}
                  fileType={selectedCourseLecturePreviewData.type}
                ></FileURLViewer>
              ) : (
                <p>Content Loading </p>
              ),
            lecturesProgress: computedLecturesProgressList
              ? computedLecturesProgressList
              : [],
            handleHide: () => {
              setShowSelectedCourseDrawer(false);
            },
            handleNavigateToCourse: handleNavigateToCourse,
          };
        } else
          return {
            showDrawer: showSelectedCourseDrawer,
            selectedCourse: lastSelectedCourse,
            selectedCoursePrerequisites: [],
            courseStatusChipProps: null,
            selectedCourseCitation: "",
            lecturePreview: <p>Content Preview Loading...</p>,
            lecturesProgress: [],
            handleHide: () => {
              setShowSelectedCourseDrawer(false);
            },
            handleNavigateToCourse: handleNavigateToCourse,
          };
      } else return null;
    }, [
      lastSelectedCourse,
      selectedCourseContent,
      selectedCourseCompletedLectures,
      showSelectedCourseDrawer,
      handleNavigateToCourse,
      coursesProgress,
      courses,
      recommendations,
      selectedCourseLecturePreviewData,
    ]);

  /**
   * handles when a course focus changes
   * @param course newly focused course, or null if focus is lost
   */
  const handleCourseFocusChange = (course: Course | null) => {
    if (!selectedCourse) {
      if (course !== null) {
        if (!focusedCourse) {
          setFocusedCourse(course);
        } else if (focusedCourse.courseNumber !== course.courseNumber) {
          setFocusedCourse(course);
        }
      } else {
        setFocusedCourse(null);
      }
    }
  };

  const setCourseProgressLocal = (newProgress: number, course: Course) => {
    const progressCopy = [...coursesProgress];
    const updatedProgressData = progressCopy.find(
      (progressData) => progressData.courseNumber === course.courseNumber,
    );
    if (updatedProgressData) updatedProgressData.progress = newProgress;
    setCoursesProgress(progressCopy);
  };

  const handleUpdateCourseProgress = async (
    course: Course,
    completed: boolean,
  ) => {
    let updatedProgress = completed ? 1 : 0;
    const user_id = localStorage.getItem("user_id");
    if (updatedProgress > 0) {
      updatedProgress = 1;
      setCourseProgressLocal(updatedProgress, course);
    } else {
      setCourseProgressLocal(-1, course);
      const completedLectures = await fetchCompletedLectures(course.id);
      if (completedLectures.length > 0) {
        updatedProgress = 0.5;
        setCourseProgressLocal(updatedProgress, course);
      } else {
        updatedProgress = 0;
        setCourseProgressLocal(updatedProgress, course);
      }
    }

    try {
      axiosInterceptor.patch(
        `course-progress/update/${user_id}/`,
        {
          updates: [
            {
              courseNumber: course.courseNumber,
              progress: updatedProgress,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    } catch (err) {
      console.log(
        `Progress update for course ${course.name} unsuccessful. Avoid reloading immediately after updating course status`,
      );
    }
  };

  return !courseDataLoaded ? (
    <ScreenSpinner />
  ) : (
    <div
      onClick={() => {
        handleCourseFocusChange(null);
        handleCourseSelectedChange(null);
      }}
      className="h-full flex flex-col gap-12 items-center py-4"
    >
      <div className="max-w-full flex flex-row items-center justify-center flex-wrap">
        <div className="flex flex-row items-center justify-between w-[1280px]">
          <div className="flex flex-row gap-2">
            <SegmentedControl
              controlItems={["Course Pathways", "Course Map"]}
              handleValueSelected={(value) => setActiveTab(value)}
            ></SegmentedControl>
          </div>
          <div className="flex flex-row items-center gap-4">
            <Dropdown>
              <Dropdown.Toggle
                as={DropdownToggle}
                message="Filter Pathways"
              ></Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 rounded-lg shadow-sm cursor-pointer !text-base !font-normal">
                {Object.keys(selectedCourseTypes).map((type) => (
                  <Dropdown.Item
                    className="active:!bg-transparent focus:!bg-transparent active:!text-black focus:!text-black"
                    key={type}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleCourseTypeChange(type);
                    }}
                  >
                    <div className="flex items-center gap-x-2 flex-row">
                      <input
                        checked={selectedCourseTypes[type]}
                        type="checkbox"
                        className="accent-[#1B4E99] w-4 h-4 rounded"
                      />
                      {type}
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <ProgressChips
              courses={courses.filter((course) =>
                Object.keys(selectedCourseTypes).some(
                  (courseType) =>
                    courseType === course.courseType &&
                    selectedCourseTypes[courseType],
                ),
              )}
              coursesProgress={coursesProgress.reduce<Record<string, number>>(
                (
                  accumulator: Record<string, number>,
                  currentProgress,
                ): Record<string, number> => {
                  accumulator[currentProgress.courseNumber] =
                    currentProgress.progress;
                  return accumulator;
                },
                {},
              )}
              isLoaded={progressDataLoaded}
            ></ProgressChips>
          </div>
        </div>
      </div>
      {activeTab === "Course Map" ? (
        <CourseMap
          courses={courses}
          recommendations={recommendations}
          isRecommendationDataLoaded={recommendationDataLoaded}
          selectedCourseTypes={selectedCourseTypes}
          coursesProgress={coursesProgress}
          selectedCourse={selectedCourse}
          focusedCourse={focusedCourse}
          handleNavigateToCourse={handleNavigateToCourse}
          handleCourseFocusChange={handleCourseFocusChange}
          handleCourseSelectedChange={handleCourseSelectedChange}
          handleUpdateCourseProgress={handleUpdateCourseProgress}
        ></CourseMap>
      ) : (
        <CoursePaths
          courses={courses}
          isProgressDataLoaded={progressDataLoaded}
          coursesProgress={coursesProgress}
          selectedCourseTypes={selectedCourseTypes}
          handleNavigateToCourse={handleNavigateToCourse}
          handleUpdateCourseProgress={handleUpdateCourseProgress}
        ></CoursePaths>
      )}
      {selectedCourseDrawerProps ? (
        <SelectedCourseDrawer
          {...selectedCourseDrawerProps}
        ></SelectedCourseDrawer>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Courses;
