import { FC, ReactElement, useState } from "react";

/**
 * Type definition for a SegmentedControlItem. Can be a string (value only), or a string and a corresponding icon
 * @typedef {Object|string} SegmentedControlItem
 * @property {string} value The value of the item
 * @property {ReactElement<SVGElement>} icon The icon element for the item
 */
export type SegmentedControlItem =
  | { value: string; icon: ReactElement<SVGElement> }
  | string;

/**
 * Interface for SegmentedControl props
 * @interface SegmentedControlProps
 * @property {string} [defaultValue] The default selected value. If provided, will search through the list of controlItems and select the controlItem with the matching value as the default item. Will set this as the default value if matching item is not found
 * @property {SegmentedControlItem[]} controlItems Array of items to be displayed in the control
 * @property {function} handleValueSelected Callback function when a value is selected
 */
export interface SegmentedControlProps {
  defaultValue?: string;
  controlItems: SegmentedControlItem[];
  handleValueSelected: (value: string) => void;
}
/**
 * SegmentedControl component
 * @component
 * @param {Object} props The props for the SegmentedControl component
 * @param {string} [props.defaultValue] The default selected value
 * @param {SegmentedControlItem[]} props.controlItems Array of items to be displayed in the control
 * @param {function} props.handleValueSelected Callback function when a value is selected
 * @returns {ReactElement} An elements that renders each value as a button and highlights selected values
 */
const SegmentedControl: FC<SegmentedControlProps> = ({
  defaultValue,
  controlItems,
  handleValueSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<SegmentedControlItem>(
    () => {
      if (defaultValue) {
        return (
          controlItems.find((item) => {
            if (typeof item === "object") {
              return item.value === defaultValue;
            } else return item === defaultValue;
          }) ?? defaultValue
        );
      } else return controlItems[0];
    },
  );

  return (
    <div className="flex flex-row items-center gap-1 bg-background-dimmer border border-background-dimmest rounded-md h-min p-[0.125rem] w-min">
      {controlItems.map((item, index) => {
        const value = typeof item === "string" ? item : item.value;
        return (
          <button
            key={index}
            onClick={() => {
              handleValueSelected(value);
              setSelectedValue(value);
            }}
            className={`
                  flex flex-row h-7 w-52 items-center justify-center gap-2 rounded-md
                  text-sm font-medium p-2 transition-colors 
                  ${
                    value === selectedValue
                      ? "text-foreground bg-background shadow-sm"
                      : "text-foreground-dimmer bg-background-dimmer hover:bg-background-dimmest"
                  }
                `}
          >
            {typeof item === "object" ? item.icon : <></>}
            <span>{value}</span>
          </button>
        );
      })}
    </div>
  );
};

export default SegmentedControl;
