import React from "react";
import { useDropzone } from "react-dropzone";

interface ResumeUploadProps {
  onFileUpload: (file: File) => void;
  resumeFile?: File | null;
}

export default function ResumeUpload({
  onFileUpload,
  resumeFile,
}: ResumeUploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onFileUpload(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className="max-w-lg mx-auto w-full">
      <h2 className="text-base font-medium mb-3">Upload Resume</h2>
      <div
        {...getRootProps()}
        className="border !border-opacity-0 border-black bg-white rounded-lg p-8 text-center cursor-pointer"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            <p>Drop the file here ...</p>
            <p className="invisible h-0">
              Drag and drop a file here or click the button below to select a
              file to upload.
            </p>
          </div>
        ) : (
          <p>
            Drag and drop a file here or click the button below to select a file
            to upload.
          </p>
        )}
        <button
          className="mt-4 px-4 py-2 bg-gray-200 rounded-md"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Select File
        </button>
        {resumeFile && (
          <div className="!mt-[1.5rem]">
            <p>Selected file: {resumeFile.name}</p>
          </div>
        )}
      </div>
    </div>
  );
}
