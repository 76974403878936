import React, { useState, useMemo } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Clock, MapPin, Award, FileText, Star } from "lucide-react";

import {
  MergedCompanyJobsSectionProps,
  CONSOLIDATED_CATEGORY_ORDER,
  ConsolidatedCategory,
  ConsolidatedJob,
} from "./interfaces";

import DynamicTabs from "./DynamicTabs";
import JobRequirementsDisplay, {
  dummyJobRequirements,
} from "./JobRequirementsDisplay";

/**
 * Deduce the final “consolidated” category and star-flag from pipeline status and rating.
 */
function mapToConsolidatedJob(job: any): ConsolidatedJob {
  let consolidatedCategory: ConsolidatedCategory = "Recommended"; // default
  let hasStar = false;

  // Pipeline overrides
  switch (job.user_pipeline_status) {
    case "Messages":
    case "New Matches":
      // Both go to "Matches". "Messages" has a star, "New Matches" doesn’t
      consolidatedCategory = "Matches";
      if (job.user_pipeline_status === "Messages") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    case "Likes You":
    case "Pending Invitations":
      // Both go to "Recommended". "Pending Invitations" has a star
      consolidatedCategory = "Recommended";
      if (job.user_pipeline_status === "Pending Invitations") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    case "Referral":
    case "Accepted":
      // Both go to "Referral". "Accepted" has a star
      consolidatedCategory = "Referral";
      if (job.user_pipeline_status === "Accepted") {
        hasStar = true;
      }
      return { ...job, consolidatedCategory, hasStar };

    default:
      // If pipeline status isn’t recognized, fall back to rating
      break;
  }

  // Fallback rating logic
  switch (job.user_rating_of_job) {
    case 1:
      consolidatedCategory = "Not Interested";
      break;
    case 3:
      consolidatedCategory = "Interested";
      break;
    case 5:
      consolidatedCategory = "Interested";
      hasStar = true;
      break;
    default:
      consolidatedCategory = "Recommended";
  }

  return { ...job, consolidatedCategory, hasStar };
}

const MergedCompanyJobsSection: React.FC<MergedCompanyJobsSectionProps> = ({
  jobs,
  onRateJob,
}) => {
  const [activeTab, setActiveTab] = useState<ConsolidatedCategory>(
    CONSOLIDATED_CATEGORY_ORDER[0],
  );

  // Convert each job to new consolidated version
  const consolidatedJobs = useMemo<ConsolidatedJob[]>(() => {
    return jobs.map(mapToConsolidatedJob);
  }, [jobs]);

  // Group them
  const categorizedJobs: Record<ConsolidatedCategory, ConsolidatedJob[]> = {
    Recommended: [],
    Interested: [],
    "Not Interested": [],
    Matches: [],
    Referral: [],
  };
  consolidatedJobs.forEach((cJob) => {
    categorizedJobs[cJob.consolidatedCategory].push(cJob);
  });

  // Track which descriptions are expanded
  const [expandedDescriptions, setExpandedDescriptions] = useState<Set<number>>(
    () => new Set(),
  );

  const handleToggleDescriptionExpand = (jobId: number) => {
    setExpandedDescriptions((prev) => {
      const newSet = new Set(prev);
      newSet.has(jobId) ? newSet.delete(jobId) : newSet.add(jobId);
      return newSet;
    });
  };

  /**
   * We consider “Matches” and “Referral” as pipeline categories
   * which should disable rating changes.
   */
  const pipelineCategories: ConsolidatedCategory[] = ["Matches", "Referral"];

  return (
    <div className="flex flex-col space-y-6">
      {/* Tabs */}
      <DynamicTabs
        categories={CONSOLIDATED_CATEGORY_ORDER}
        categorizedJobs={categorizedJobs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {/* Jobs Wrapper */}
      <div className="divide-y divide-gray-200">
        {categorizedJobs[activeTab].length === 0 && (
          <div className="p-6 text-center text-lg text-gray-500">
            No jobs found in this category.
          </div>
        )}

        {categorizedJobs[activeTab]
          .slice()
          .sort((a, b) => {
            // starred (hasStar) should sort to the top
            if (a.hasStar === b.hasStar) return 0;
            return a.hasStar ? -1 : 1;
          })
          .map((job) => {
            const jobIsLocked = pipelineCategories.includes(
              job.consolidatedCategory,
            );
            const currentRating = job.user_rating_of_job;
            const maxLen = 150;
            const fullDesc = job.description || "";
            const isExpanded = expandedDescriptions.has(job.id);
            const shortDesc = `${fullDesc.substring(0, maxLen)}...`;
            const displayDesc =
              fullDesc.length > maxLen && !isExpanded ? shortDesc : fullDesc;

            // Determine rating dropdown label/variant
            let dropdownLabel = "Rate This Job";
            let dropdownVariant: any = "outline-secondary";

            if (currentRating === 1) {
              dropdownLabel = "Not Interested";
              dropdownVariant = "danger";
            } else if (currentRating === 3) {
              dropdownLabel = "Interested";
              dropdownVariant = "primary";
            } else if (currentRating === 5) {
              dropdownLabel = "Very Interested";
              dropdownVariant = "success";
            }

            return (
              <div key={job.id} className="py-6 transition-colors">
                {/* HEADER row */}
                <div className="flex items-start justify-between mb-6">
                  <div className="flex items-center gap-2 flex-wrap">
                    <h3 className="text-2xl font-semibold text-gray-900">
                      {job.title}
                    </h3>
                    {job.hasStar && (
                      <Star className="w-5 h-5 text-yellow-500" />
                    )}
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-[#0059EC]">
                      ${Math.round(job.compensationLowerBound / 1000)}k – $
                      {Math.round(job.compensationUpperBound / 1000)}k
                    </span>
                    {job.eligible_for_visa_sponsorship && (
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-[#0059EC]">
                        Visa Sponsorship
                      </span>
                    )}
                  </div>

                  <DropdownButton
                    id={`rating-dropdown-${job.id}`}
                    title={dropdownLabel}
                    variant={dropdownVariant}
                    size="lg"
                    className="shrink-0"
                    disabled={jobIsLocked}
                  >
                    <Dropdown.Item onClick={() => onRateJob(job.id, 1)}>
                      Not Interested
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onRateJob(job.id, 3)}>
                      Interested
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onRateJob(job.id, 5)}>
                      Very Interested
                    </Dropdown.Item>
                  </DropdownButton>
                </div>

                {/* REQUIREMENTS (example/placeholder) */}
                <div className="mb-6">
                  <JobRequirementsDisplay requirements={dummyJobRequirements} />
                </div>

                {/* 4-column layout for location, benefits, description, timeline */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8">
                  {/* LOCATION */}
                  <div className="flex items-start gap-4 mb-4 md:mb-0">
                    <MapPin className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
                    <div>
                      <h4 className="text-xl font-semibold text-gray-900 mb-2">
                        Location
                      </h4>
                      <p className="text-base text-gray-700">
                        {job.city || "Work from home"}
                      </p>
                    </div>
                  </div>

                  {/* BENEFITS */}
                  <div className="flex items-start gap-4 mb-4 md:mb-0">
                    <Award className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
                    <div>
                      <h4 className="text-xl font-semibold text-gray-900 mb-2">
                        Benefits
                      </h4>
                      <p className="text-base text-gray-700">
                        {job.benefits || "No benefits info provided"}
                      </p>
                    </div>
                  </div>

                  {/* DESCRIPTION */}
                  <div className="flex items-start gap-4 mb-4 md:mb-0">
                    <FileText className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
                    <div>
                      <h4 className="text-xl font-semibold text-gray-900 mb-2">
                        Description
                      </h4>
                      <p className="text-base text-gray-700 whitespace-pre-line">
                        {displayDesc}
                        {fullDesc.length > maxLen && (
                          <button
                            onClick={() =>
                              handleToggleDescriptionExpand(job.id)
                            }
                            className="ml-2 text-blue-600 hover:text-blue-700"
                          >
                            {isExpanded ? "View less" : "More"}
                          </button>
                        )}
                      </p>
                    </div>
                  </div>

                  {/* TIMELINE */}
                  <div className="flex items-start gap-4">
                    <Clock className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
                    <div>
                      <h4 className="text-xl font-semibold text-gray-900 mb-2">
                        Timeline
                      </h4>
                      <div className="space-y-2">
                        <div>
                          <p className="text-md font-medium text-gray-500">
                            Interview Start Date
                          </p>
                          <p className="text-base text-gray-700">
                            {job.interviewingStartDate || "N/A"}
                          </p>
                        </div>
                        <div>
                          <p className="text-md font-medium text-gray-500">
                            Job Start Date
                          </p>
                          <p className="text-base text-gray-700">
                            {job.jobStartDate || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end 4-column layout */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MergedCompanyJobsSection;
