import React, { useRef, useState, useEffect } from "react";
import Avatar from "boring-avatars";
import { ExternalLink } from "lucide-react";
import { Modal } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";
import EditProfile from "./EditProfile";
import { PersonalInfo } from "./interfaces";

interface ProfileHeaderProps {
  name: string;
  phone: string;
  email: string;
  address: string;
  birthday: string;
  visa: string;
  website: string;
  linkedin: string;
  github: string;
  countries: string[];
  user_data: PersonalInfo;
  userId: string;
}

const PencilIcon = ({
  className,
  loading,
}: {
  className: string;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <svg
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      >
        <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 12 12"
            to="360 12 12"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
    </svg>
  );
};

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  phone,
  email,
  address,
  birthday,
  visa,
  website,
  linkedin,
  github,
  countries,
  user_data,
  userId,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axiosInterceptor.get(
          `serve_private_doc/user_career_data/user_id=${userId}/images/`,
        );
        if (response.data?.private_docs?.length) {
          const [presignedUrl] =
            response.data.private_docs[response.data.private_docs.length - 1];
          setProfileImage(presignedUrl);
        }
      } catch (err) {
        console.error("Error fetching profile image:", err);
      }
    };
    fetchProfileImage();
  }, [userId]);

  const handleImageUploadClick = () => fileInputRef.current?.click();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      alert("Please select a JPG or PNG image.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      await axiosInterceptor.post(`images/upload/${userId}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
      });
      const response = await axiosInterceptor.get(
        `serve_private_doc/user_career_data/user_id=${userId}/images/`,
      );
      if (response.data?.private_docs?.length) {
        const [presignedUrl] = response.data.private_docs[0];
        setProfileImage(presignedUrl);
      }
    } catch (error) {
      console.error("Error uploading profile image:", error);
      alert("Failed to upload profile image.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
      {/* Header */}
      <div className="pl-9 pr-5 pt-2">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-4 flex-grow">
            {/* Profile Image */}
            <div className="relative">
              <div className="mt-1.5 w-16 h-16 rounded-full overflow-hidden bg-gray-100">
                {profileImage ? (
                  <img
                    src={profileImage}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <Avatar
                    name={userId}
                    variant="beam"
                    size={64}
                    colors={["#99CBFF", "#EDEDED"]}
                  />
                )}
              </div>
              <button
                onClick={handleImageUploadClick}
                disabled={isLoading}
                className={`absolute -bottom-1 -right-1 bg-white rounded-full p-1 shadow-sm ${
                  isLoading ? "cursor-not-allowed" : "hover:bg-gray-50"
                } border border-gray-200`}
              >
                <PencilIcon
                  className="w-3 h-3 text-gray-600"
                  loading={isLoading}
                />
              </button>
            </div>

            <div>
              <h2 className="ml-0.5 text-2xl font-semibold text-gray-900">
                {name}
              </h2>
            </div>
          </div>
          <button
            onClick={() => setShowEdit(true)}
            disabled={isLoading}
            className={`w-8 h-8 flex items-center justify-center text-gray-400 ${
              isLoading ? "cursor-not-allowed" : "hover:text-gray-600"
            } rounded-full`}
          >
            <PencilIcon className="w-5 h-5" loading={isLoading} />
          </button>
        </div>
      </div>

      {/* Rest of the component remains unchanged */}
      <div className="ml-28 px-3 py-0">
        <div className="grid grid-cols-3 gap-x-6 gap-y-4">
          {/* Personal Info */}
          <div className="flex items-start gap-3">
            <div>
              <div className="mt-4 space-y-3 mb-4">
                <div>
                  <p className="text-md font-medium text-gray-500">Address</p>
                  <p className="text-base font-normal text-gray-900 mt-1">
                    {address || "Not specified"}
                  </p>
                </div>
                <div>
                  <p className="text-md font-medium text-gray-500">
                    Date of Birth
                  </p>
                  <p className="text-base font-normal text-gray-900 mt-1">
                    {birthday || "Not specified"}
                  </p>
                </div>
                <div>
                  <p className="text-md font-medium text-gray-500">
                    Visa Status
                  </p>
                  <p className="text-base font-normal text-gray-900 mt-1 mb-3">
                    {visa || "Not specified"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Info */}
          <div className="flex items-start gap-3">
            <div>
              <div className="mt-4 space-y-3">
                <div>
                  <p className="text-md font-medium text-gray-500">Phone</p>
                  <p className="text-base font-normal text-gray-900 mt-1">
                    {phone || "Not specified"}
                  </p>
                </div>
                <div>
                  <p className="text-md font-medium text-gray-500">Email</p>
                  <p className="text-base font-normal text-gray-900 mt-1">
                    {email}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Links */}
          <div className="flex items-start gap-3">
            <div>
              <div className="mt-4 space-y-3">
                <div>
                  <p className="text-md font-medium text-gray-500">Website</p>
                  {website ? (
                    <a
                      href={website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base font-normal text-[#0059EC] hover:text-blue-700 flex items-center gap-1 mt-1"
                    >
                      {website.replace(/^https?:\/\//, "")}
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  ) : (
                    <p className="text-base font-medium text-gray-400 mt-1">
                      Not specified
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-md font-medium text-gray-500">LinkedIn</p>
                  {linkedin ? (
                    <a
                      href={linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base font-normal text-[#0059EC] hover:text-blue-700 flex items-center gap-1 mt-1"
                    >
                      {linkedin.replace(/^https?:\/\//, "")}
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  ) : (
                    <p className="text-base font-medium text-gray-400 mt-1">
                      Not specified
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-md font-medium text-gray-500">GitHub</p>
                  {github ? (
                    <a
                      href={github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base font-normal text-[#0059EC] hover:text-blue-700 flex items-center gap-1 mt-1 mb-4"
                    >
                      {github.replace(/^https?:\/\//, "")}
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  ) : (
                    <p className="text-base font-medium text-gray-400 mt-1">
                      Not specified
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Profile Modal */}
      <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProfile existingInfo={user_data} />
        </Modal.Body>
      </Modal>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfileHeader;
