import React, { useState, useEffect } from "react";
import { TranscriptProps, CreateTransProps } from "../interfaces";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";
import { File, X } from "lucide-react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { Tabs, Tab } from "react-bootstrap";

const TranscriptComponent: React.FC<TranscriptProps> = ({ transcript }) => {
  return (
    <div className="flex flex-col gap-3">
      {transcript.map((trans, index) => (
        <div key={index} className="flex flex-row items-start gap-4">
          <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-orange-50 flex items-center justify-center">
            <File className="w-6 h-6 text-orange-600" />
          </div>

          <div className="flex-grow">
            <a
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="group"
            >
              <h3 className="text-base font-semibold text-gray-900 group-hover:text-[#0059EC]">
                {trans.course}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                Click to view document
              </p>
            </a>
          </div>
        </div>
      ))}
      {transcript.length === 0 && <div className="text-sm text-gray-500"></div>}
    </div>
  );
};

// Component for uploading transcripts
const UploadTranscript: React.FC<{ userId: string }> = ({ userId }) => {
  return (
    <Form>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="transcripts"
          uploadEndpoint={`file_upload/`}
        />
      </Stack>
    </Form>
  );
};

// Component for deleting transcripts
const DeleteTranscript: React.FC<{
  userId: string;
  onDelete?: () => void;
}> = ({ userId, onDelete }) => {
  const [currentTranscripts, setCurrentTranscripts] = useState<
    [string, string][]
  >([]);
  const [error, setError] = useState<string | null>(null);

  const fetchTranscripts = async () => {
    try {
      const filepath = `user_career_data/user_id=${userId}/transcripts/`;
      const response = await axiosInterceptor.get<{
        private_docs: [string, string][];
      }>(`/serve_private_doc/${filepath}`);
      setCurrentTranscripts(response.data.private_docs || []);
    } catch (err) {
      console.error("Error fetching transcripts:", err);
      setError("Failed to fetch transcripts");
    }
  };

  useEffect(() => {
    fetchTranscripts();
  }, [userId]);

  const handleDelete = async (fileUrl: string) => {
    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      await axiosInterceptor.delete(`/api/delete_transcript/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRFToken": csrfToken,
        },
        data: {
          fileUrl: fileUrl,
        },
      });
      // Refresh the list after successful deletion
      await fetchTranscripts();
      if (onDelete) {
        onDelete();
      }
    } catch (err) {
      console.error("Error deleting transcript:", err);
      setError("Failed to delete transcript");
    }
  };

  if (error) {
    return <p className="text-red-500 text-sm">{error}</p>;
  }

  return (
    <div className="p-4">
      <h5 className="mb-4 text-lg font-medium">Select Transcripts to Delete</h5>
      {currentTranscripts.length > 0 ? (
        <div className="space-y-2">
          {currentTranscripts.map(([url, fileName], index) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                <File className="w-5 h-5 text-orange-600" />
                <span className="text-sm font-medium">{fileName}</span>
              </div>
              <button
                className="p-2 hover:bg-gray-200 rounded-full transition-colors"
                title="Delete transcript"
                onClick={() => handleDelete(url)}
              >
                <X className="w-5 h-5 text-red-500" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-sm">No transcripts to delete</p>
      )}
    </div>
  );
};

// Combined component for managing transcripts (both upload and delete)
const ManageTranscripts: React.FC<{
  userId: string;
  onDelete?: () => void;
}> = ({ userId, onDelete }) => {
  return (
    <Tabs defaultActiveKey="upload" className="mb-3">
      <Tab eventKey="upload" title="Upload Transcript">
        <UploadTranscript userId={userId} />
      </Tab>
      <Tab eventKey="manage" title="Manage Transcripts">
        <DeleteTranscript userId={userId} onDelete={onDelete} />
      </Tab>
    </Tabs>
  );
};

const CreateTrans: React.FC<CreateTransProps> = ({ transcript, userId }) => {
  return (
    <ManageTranscripts
      userId={userId}
      onDelete={async () => {
        // The parent component will handle the refresh through onEditModalClose
      }}
    />
  );
};

export default TranscriptComponent;
export { CreateTrans };
