import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { Button } from "react-bootstrap";
import { CareerCardProps } from "../interfaces";
import { Trash2, Loader2 } from "lucide-react";
import { profileIcons } from "../../../icons";
import { useNavigate } from "react-router-dom";

interface ExtendedCareerCardProps extends CareerCardProps {
  directEditPath?: string;
  isLoading?: boolean;
  onDeleteModalClose?: () => Promise<void>;
  onEditModalClose?: () => Promise<void>;
}

const CareerCard: React.FC<ExtendedCareerCardProps> = ({
  title,
  children,
  showAddButton = true,
  showDeleteButton = false,
  showEditAsModal = true,
  showEditButton = true,
  modalTitle = title,
  deleteModalTitle = "Delete",
  directEditPath,
  isLoading = false,
  onDeleteModalClose,
  onEditModalClose,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const handleDeleteModalClose = async () => {
    setShowDeleteModal(false);
    if (onDeleteModalClose) {
      await onDeleteModalClose();
    }
  };

  const handleEditModalClose = async () => {
    setShowEditModal(false);
    if (onEditModalClose) {
      await onEditModalClose();
    }
  };

  const getIcon = () => {
    const iconProps = { className: "w-7 h-7 text-[#0059EC] flex-shrink-0" };
    switch (title) {
      case "Education":
        return <profileIcons.career.education {...iconProps} />;
      case "Experience":
        return <profileIcons.career.experience {...iconProps} />;
      case "External Courses":
        return <profileIcons.career.courses {...iconProps} />;
      case "Skills":
        return <profileIcons.career.skills {...iconProps} />;
      case "Resume/CV":
        return <profileIcons.career.resume {...iconProps} />;
      case "Transcript":
        return <profileIcons.career.transcript {...iconProps} />;
      case "Job Preferences":
        return <profileIcons.career.jobPreferences {...iconProps} />;
      case "Personality":
        return <profileIcons.career.personality {...iconProps} />;
      default:
        return <profileIcons.career.tests {...iconProps} />;
    }
  };

  const handleEditClick = () => {
    if (isLoading) return;
    if (directEditPath) {
      navigate(directEditPath);
    } else if (showEditAsModal) {
      setShowEditModal(true);
    }
  };

  return (
    <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
      <div className="px-4 py-4">
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center gap-3">
            {getIcon()}
            <h4 className="mt-0.5 text-xl font-semibold text-gray-900">
              {title}
            </h4>
          </div>
          <div className="flex flex-row gap-x-2">
            {showAddButton && (
              <>
                <Button
                  variant="light"
                  className="flex items-center gap-2 text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                  onClick={() => setShowAddModal(true)}
                  disabled={isLoading}
                >
                  <i className="bi bi-plus-lg"></i>
                  Add
                </Button>
                <Modal
                  title={title}
                  show={showAddModal}
                  setShow={setShowAddModal}
                >
                  {showEditButton ? children[2] : children[1]}
                </Modal>
              </>
            )}
            {showDeleteButton && (
              <>
                <button
                  onClick={() => !isLoading && setShowDeleteModal(true)}
                  className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-red-600 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 size={20} className="animate-spin" />
                  ) : (
                    <Trash2 size={20} />
                  )}
                </button>
                <Modal
                  title={deleteModalTitle}
                  show={showDeleteModal}
                  setShow={handleDeleteModalClose}
                >
                  {children[2]}
                </Modal>
              </>
            )}
            {showEditButton && (
              <button
                onClick={handleEditClick}
                className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 size={20} className="animate-spin" />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
                  </svg>
                )}
              </button>
            )}
            {!directEditPath && showEditAsModal && showEditModal && (
              <Modal
                title={modalTitle}
                show={showEditModal}
                setShow={handleEditModalClose}
              >
                {children[1]}
              </Modal>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 pb-4">
        {children[0]}
        {!showEditAsModal && showEditModal && children[1]}
      </div>
    </div>
  );
};

export default CareerCard;
