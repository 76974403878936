import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CameraRecorder from "./CameraRecorder";
import Webcam from "react-webcam";
import { Question } from "../types";
import HorizontalProgress from "../../../components/HorizontalProgress";
import {
  DefaultButton,
  LightNegativeButton,
  PrimaryButton,
} from "../../../components/Button";

interface InterviewTemplateProps {
  type: string;
  questions: Question[];
}

interface CameraRecorderRef {
  stopAndDownload: () => void;
}

export default function InterviewTemplate({
  type,
  questions,
}: InterviewTemplateProps): JSX.Element {
  const navigate = useNavigate();
  const cameraRecorderRef = useRef<CameraRecorderRef | null>(null);
  const [isQuestionsBegun, setIsQuestionsBegun] = useState<boolean>(false);
  const [questionsAsked, setQuestionsAsked] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const numQuestionsToAsk = questionsAsked.length;

  useEffect(() => {
    setQuestionsAsked(questions);
  }, [questions]);

  const startInterview = () => {
    setCurrentQuestionIndex(0);
    setIsQuestionsBegun(true);
  };

  const handleExitClick = () => {
    setShowExitModal(true);
  };

  const handleCloseExitModal = () => {
    setShowExitModal(false);
  };

  const handleNextQuestion = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }

      if (currentQuestionIndex === questionsAsked.length - 1) {
        navigate("/test-center/home");
      } else {
        setCurrentQuestionIndex((prev) => prev + 1);
      }
    } catch (err) {
      console.error("Question transition error:", err);
      setError("Failed to proceed to next question");
    }
  };

  const exitPage = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      navigate("/test-center/home");
    } catch (err) {
      console.error("Exit error:", err);
    }
  };

  const handleConfirmExit = async () => {
    handleCloseExitModal();
    await exitPage();
  };

  return (
    <div className="h-min bg-background p-4">
      {/* Header */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mt-2 mb-4">{type} Exam</h2>
      </div>
      {/* Main Content */}
      <div className="bg-background rounded-xl flex flex-col gap-20">
        {isQuestionsBegun ? (
          currentQuestionIndex < questionsAsked.length ? (
            <>
              {/* Question Section */}
              <p className="text-md text-foreground-dimmer">
                <b className="text-foreground">
                  Question {currentQuestionIndex + 1}:{" "}
                </b>
                {questionsAsked[currentQuestionIndex].question}
              </p>

              {/* Camera Section */}
              <div className="w-full max-w-2xl mx-auto">
                <CameraRecorder
                  isBackground={true}
                  ref={cameraRecorderRef}
                  ids={questionsAsked.map((question) => Number(question.id))}
                  type={type}
                />
              </div>
            </>
          ) : (
            <p className="text-xl font-medium">
              You have completed all questions!
            </p>
          )
        ) : (
          <>
            {/* Instructions */}
            <p className="text-foreground-dimmer max-w-4xl">
              <b className="text-foreground">Instructions: </b>
              You will be given a set of {type.toLowerCase()} interview
              questions. Please answer each question within the time limit using
              the STAR response format. Submit each answer when you are done
              with your response.
            </p>
            {/* Camera Preview */}
            <div className="w-full max-w-2xl mx-auto">
              <Webcam className="rounded-lg w-full" />
            </div>
          </>
        )}
        {/* Test Status */}
        <div className="flex justify-between items-center flex-wrap-reverse gap-8">
          {/* Progress Bar */}
          <div className="flex-1 flex flex-wrap-reverse items-center gap-4">
            <div className="min-w-96 max-w-96 flex-1">
              <HorizontalProgress
                progress={(currentQuestionIndex + 1) / numQuestionsToAsk}
              ></HorizontalProgress>
            </div>
            <span className="text-md text-foreground-dimmer text-nowrap">
              {isQuestionsBegun
                ? `${currentQuestionIndex + 1}/${
                    questionsAsked.length
                  } Questions`
                : "Test not started yet"}
            </span>
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4">
            {!isQuestionsBegun ? (
              <PrimaryButton
                handleClick={startInterview}
                message="Start Interview"
              ></PrimaryButton>
            ) : (
              currentQuestionIndex < questionsAsked.length && (
                <PrimaryButton
                  handleClick={handleNextQuestion}
                  message={
                    currentQuestionIndex === questionsAsked.length - 1
                      ? "Submit Test"
                      : "Next Question"
                  }
                ></PrimaryButton>
              )
            )}
            {isQuestionsBegun && (
              <DefaultButton
                handleClick={handleExitClick}
                message="Exit Interview"
              ></DefaultButton>
            )}
          </div>
        </div>
      </div>
      {/* Exit Modal */}
      {showExitModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
            <h4 className="text-2xl font-semibold mb-4">Are you sure?</h4>
            <p className="text-md text-foreground-dimmer mb-6">
              Exiting the test will end your interview and cause you to lose all
              your progress!
            </p>
            <div className="flex justify-end gap-4">
              <DefaultButton
                handleClick={handleCloseExitModal}
                message="Cancel"
              ></DefaultButton>
              <LightNegativeButton
                handleClick={handleConfirmExit}
                message="I'm Sure"
              ></LightNegativeButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}
