import React, { useEffect, useState } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import SegmentedControl from "../../components/SegmentedControl";
import { VideoCard } from "./VideoCard";
import MergedInstructionsTestCard from "./InstructionsCard";
import TestCenterResults from "./TestCenterResults";
import { dummyAggregatedAssessment, testOptions } from "./constants";
import {
  TestType,
  AttemptType,
  TestAttemptInfo,
  AggregatedAssessmentExtended,
  TechnicalGroupKey,
  BehavioralGroupKey,
  JobTitleBucketKey,
} from "./interfaces";

const TestCenter: React.FC = () => {
  const userID = localStorage.getItem("user_id");
  const [selectedSegment, setSelectedSegment] = useState<"Results" | "Videos">(
    "Results",
  );
  const [selectedTest, setSelectedTest] = useState<TestType>("technical");
  const [selectedAttempt, setSelectedAttempt] =
    useState<AttemptType>("attempt1");
  const [testData, setTestData] = useState<TestAttemptInfo | null>(null);

  // Aggregated assessment state
  const [assessment, setAssessment] = useState<AggregatedAssessmentExtended>(
    dummyAggregatedAssessment,
  );

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const response = await axiosInterceptor.get<TestAttemptInfo>(
          `/api/get_test_results/${userID}/`,
          {
            params: {
              test_type: selectedTest,
              attempt_number: selectedAttempt.replace("attempt", ""),
            },
          },
        );
        setTestData(response.data);
      } catch (error) {
        console.error("Error fetching test attempt data:", error);
      }
    };

    if (userID && selectedSegment === "Videos") {
      fetchTestResults();
    }
  }, [userID, selectedTest, selectedAttempt, selectedSegment]);

  const handleTechnicalToggle = (
    groupKey: TechnicalGroupKey,
    skillName: string,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      technical: {
        ...prev.technical,
        [groupKey]: prev.technical[groupKey].map((skill) =>
          skill.name === skillName
            ? { ...skill, selected: !skill.selected }
            : skill,
        ),
      },
    }));
  };

  // Toggle background job title bucket items
  const handleBackgroundToggle = (
    bucketKey: JobTitleBucketKey,
    attrName: string,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      background: {
        ...prev.background,
        [bucketKey]: prev.background[bucketKey].map((item) =>
          item.name === attrName ? { ...item, selected: !item.selected } : item,
        ),
      },
    }));
  };

  const handleBehavioralToggle = (
    attributeName: string,
    groupName: BehavioralGroupKey,
  ): void => {
    setAssessment((prev) => ({
      ...prev,
      behavioral: {
        ...prev.behavioral,
        [groupName]: {
          ...prev.behavioral[groupName],
          [attributeName]: {
            ...prev.behavioral[groupName][attributeName],
            selected: !prev.behavioral[groupName][attributeName].selected,
          },
        },
      },
    }));
  };

  const renderVideosView = () => {
    const attemptIndex =
      parseInt(selectedAttempt.replace("attempt", ""), 10) - 1;
    const videoUrl = testData?.videoUrls?.[attemptIndex] ?? null;

    return (
      <div className="p-4">
        <div className="flex items-center gap-2 mb-4">
          <select
            value={selectedTest}
            onChange={(e) => setSelectedTest(e.target.value as TestType)}
            className="border rounded px-2 py-1 text-sm focus:ring-1"
          >
            <option value="technical">Technical</option>
            <option value="background">Background</option>
            <option value="behavioral">Behavioral</option>
          </select>
          <select
            value={selectedAttempt}
            onChange={(e) => setSelectedAttempt(e.target.value as AttemptType)}
            className="border rounded px-2 py-1 text-sm focus:ring-1"
          >
            <option value="attempt1">Attempt 1</option>
            <option value="attempt2">Attempt 2</option>
          </select>
        </div>
        <div className="flex justify-center">
          <VideoCard
            videoUrl={videoUrl || undefined}
            title={`${
              selectedTest.charAt(0).toUpperCase() + selectedTest.slice(1)
            } Interview ${selectedAttempt.replace("attempt", "Attempt ")}`}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white p-4">
      <div className="max-w-7xl mx-auto">
        <MergedInstructionsTestCard
          title="Test Zone: Show Strengths & Grow Skills"
          paragraphs={[
            "This zone is a dedicated personal coach and advocate for career growth. After taking our tests, our algorithms recommend what strengths to show for recruiters/referrers actively seeking your expertise. Then, our algorithms recommend crash courses to unlock new strengths and sharpen your current strengths",
            "Our goals are simple: make the job market fair for everyone and help people grow. Our judgment-free environment is designed to be your training buddy: we’ll pinpoint what you do well, guide you to hone your abilities, and consistently present your best self to potential employers.",
          ]}
          testOptions={testOptions}
        />

        <div className="pt-4 border-t border-gray-200 flex justify-start">
          <SegmentedControl
            defaultValue={selectedSegment}
            controlItems={["Results", "Videos"]}
            handleValueSelected={(val) =>
              setSelectedSegment(val as "Results" | "Videos")
            }
          />
        </div>

        <div className="bg-white rounded-xl overflow-hidden flex flex-col mt-2">
          {selectedSegment === "Results" ? (
            <TestCenterResults
              technicalGroups={assessment.technical}
              backgroundGroups={assessment.background}
              behavioralGroups={assessment.behavioral}
              onTechnicalToggle={handleTechnicalToggle}
              onBackgroundToggle={handleBackgroundToggle}
              onBehavioralToggle={handleBehavioralToggle}
            />
          ) : (
            renderVideosView()
          )}
        </div>
      </div>
    </div>
  );
};

export default TestCenter;
