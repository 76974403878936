import React from "react";
import NetworkBackground from "../../assets/img/landing/wire-background.png";
import RecruitmentBackground from "../../assets/img/landing/recruitment-background.png";

const RecruitmentSection = () => {
  return (
    <div className="w-full">
      {/* Reimagined Section with Full-width Gradient */}
      <div className="w-full min-h-[600px] relative bg-gradient-to-b from-gray-50 to-gray-100">
        {/* Background image container - spans full width */}
        <div className="absolute inset-0 w-full h-full">
          <img
            src={RecruitmentBackground}
            alt="Recruitment Background"
            className="w-full h-full object-cover opacity-100"
          />
        </div>

        {/* Content - centered both horizontally and vertically */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="max-w-7xl mx-auto px-4 w-full">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl md:text-5xl font-bold text-blue-800 mb-8">
                Accurate Job Matching for
                <br />
                <span className="text-[#0059EC]">Tech</span> and <span> </span>
                <span className="text-[#0059EC]">Finance </span>
              </h2>
              <p className="text-black text-lg leading-relaxed max-w-2xl mx-auto">
                Recruitment doesn't have to be a headache. AlgoLink uses state
                of the art scientific techniques to connect you with skilled
                recruiters and current employees in your ideal roles
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="max-w-7xl mx-auto px-4 mb-32">
        <div className="w-full rounded-3xl overflow-hidden relative bg-gray-100">
          <div className="absolute inset-0">
            <img 
              src={NetworkBackground} 
              alt="Network Background" 
              className="w-full h-full object-cover"
            />
          </div>
          
          <div className="relative p-12 md:p-16 max-w-2xl ml-auto">
            <div className="backdrop-blur-lg bg-gray-200/30 rounded-3xl p-8 md:p-12">
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Recruitment Made Easy, Tailored for Your Business.
              </h1>
              <p className="text-white/90 text-lg leading-relaxed">
                Finding the right talent doesn't have to be complicated. 
                AlgoLink uses advanced tools to match you with skilled 
                professionals who meet your needs and fit your company culture.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RecruitmentSection;
