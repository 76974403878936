import React, { useState, useEffect } from "react";
import CareerCard from "./career/CareerCard";
import { PDFViewer } from "./career/DocumentViewer";
import EducationComponent, { CreateEdu } from "./career/Education";
import ExperienceComponent, { CreateExp } from "./career/Experience";
import { ExternalCourse, CreateCourse } from "./career/ExternalCourses";
import { Skills, CreateSkill } from "./career/Skills";
import ResumeComponent, { CreateRes } from "./career/Resume";
import TranscriptComponent, { CreateTrans } from "./career/Transcript";
import ChecklistCard from "./ChecklistCard";
import PersonalityScoreCard from "./surveys/PersonalityScoreCard";
import axiosInterceptor from "../../utils/axiosInterceptor";

// For the new "Top 5 Jobs" display
import Badge from "../../components/Badge";
import SurveyLinkContainer, { SurveyLink } from "./surveys/SurveyLink";

import {
  Skill,
  EducationItem,
  ExperienceInfo,
  Course,
  ResumeItem,
  TranscriptItem,
  PersonalityScores,
  FullUserData,
} from "./interfaces";
import { transformAnswers, validateAnswers } from "./helpers";

interface CareerProfileProps {
  userData: FullUserData;
  personalityScores?: PersonalityScores;
  userId: string;
}

const BLANK_SKILLS: Skill[] = [];
const BLANK_EDUCATION: EducationItem[] = [];
const BLANK_COURSES: Course[] = [];
const BLANK_EXPERIENCE: ExperienceInfo[] = [];
const BLANK_RESUME: ResumeItem[] = [];
const BLANK_TRANSCRIPT: TranscriptItem[] = [];

const CareerProfile: React.FC<CareerProfileProps> = ({
  userData,
  personalityScores,
  userId,
}) => {
  // const userId = localStorage.getItem("user_id") || "";

  // States for the user's arrays
  const [education, setEducation] = useState<EducationItem[]>(BLANK_EDUCATION);
  const [experiences, setExperiences] =
    useState<ExperienceInfo[]>(BLANK_EXPERIENCE);
  const [courses, setCourses] = useState<Course[]>(BLANK_COURSES);
  const [skills, setSkills] = useState<Skill[]>(BLANK_SKILLS);
  const [resume, setResume] = useState<ResumeItem[]>(BLANK_RESUME);
  const [transcript, setTranscript] =
    useState<TranscriptItem[]>(BLANK_TRANSCRIPT);

  // Add loading states
  const [isResumeLoading, setIsResumeLoading] = useState(false);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [resumeRefreshTrigger, setResumeRefreshTrigger] = useState(0);
  const [transcriptRefreshTrigger, setTranscriptRefreshTrigger] = useState(0);

  // For personality results
  // const [personalityScores, setPersonalityScores] =
  //   useState<PersonalityScores>();

  // Add refresh functions
  const refreshResume = async () => {
    try {
      setIsResumeLoading(true);
      const response = await axiosInterceptor.get<FullUserData>(
        `/getuser/${userId}/`,
      );
      setResume(response.data.resume || []);
      setResumeRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error refreshing resume:", error);
    } finally {
      setIsResumeLoading(false);
    }
  };

  const refreshTranscript = async () => {
    try {
      setIsTranscriptLoading(true);
      const response = await axiosInterceptor.get<FullUserData>(
        `/getuser/${userId}/`,
      );
      setTranscript(response.data.transcript || []);
      setTranscriptRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error refreshing transcript:", error);
    } finally {
      setIsTranscriptLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data + user survey data in parallel
        const [allDataResponse, surveyResponse] = await Promise.all([
          axiosInterceptor.get<FullUserData>(`/getuser/${userId}/`),
          axiosInterceptor.get(`/save_or_update_user_surveys/${userId}/`),
        ]);
        const userData = allDataResponse.data;

        // Populate arrays
        setEducation(userData.ed_data || []);
        setCourses(userData.course_data || []);
        setExperiences(userData.exp_data || []);
        setSkills(userData.skill_data || []);
        setResume(userData.resume || []);
        setTranscript(userData.transcript || []);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  // For associating courses w/ correct education ID
  const userEds = education.map(({ id, school, degree }) => ({
    id,
    school,
    degree,
  }));

  // A dummy list of top 5 roles (pull real data if you like)
  const topJobs = [
    "Quant Trader",
    "Quant Developer",
    "Data Scientist",
    "Machine Learning Engineer",
    "Research Scientist",
  ];

  return (
    <div className="mt-3 flex flex-row gap-4 w-full">
      {/* LEFT COLUMN */}
      <div className="flex flex-col gap-y-4 w-2/3">
        {/* Education */}
        <CareerCard title="Education" showAddButton={false}>
          <EducationComponent eduList={education} />
          <CreateEdu eduList={education} setEducation={setEducation} />
        </CareerCard>

        {/* Experience */}
        <CareerCard title="Experience" showAddButton={false}>
          <ExperienceComponent expList={experiences} />
          <CreateExp expList={experiences} setExperiences={setExperiences} />
        </CareerCard>

        {/* External Courses */}
        <CareerCard title="External Courses" showAddButton={false}>
          <ExternalCourse courseList={courses} />
          <CreateCourse
            courseList={courses}
            setCourse={setCourses}
            userEds={userEds}
          />
        </CareerCard>

        {/* Skills */}
        <CareerCard title="Skills" showAddButton={false}>
          <Skills skills={skills} />
          <CreateSkill skills={skills} setSkills={setSkills} />
        </CareerCard>
      </div>

      {/* RIGHT COLUMN */}
      <div className="flex flex-col gap-y-3 w-1/3">
        {/* 1) Job Preferences */}
        <CareerCard
          title="Job Preferences"
          showAddButton={false}
          directEditPath="/job-preferences-survey"
        >
          <div className="ml-4 flex flex-wrap gap-2 mt-2">
            {topJobs.map((job, idx) => (
              <Badge key={idx} text={job} variant="blue" />
            ))}
          </div>
          <div className="mt-5 ml-4">
            <SurveyLink
              text="Edit Job Preferences"
              path="/job-preferences-survey"
            />
          </div>
        </CareerCard>

        {/* 2) Personality */}
        <CareerCard
          title="Personality"
          showAddButton={false}
          directEditPath="/personality-survey"
        >
          <PersonalityScoreCard scores={personalityScores} />
          <div className="mt-4 ml-4">
            <SurveyLink
              text="Take/Retake Personality Survey"
              path="/personality-survey"
            />
          </div>
        </CareerCard>

        {/* 3) Resume */}
        <CareerCard
          title="Resume/CV"
          showAddButton={false}
          showDeleteButton={false}
          modalTitle="Manage Resumes"
          isLoading={isResumeLoading}
          onEditModalClose={refreshResume}
        >
          <div className="ml-11">
            <PDFViewer
              userId={userId}
              documentType="Resume"
              refreshTrigger={resumeRefreshTrigger}
            />
            <div className="mt-2">
              <ResumeComponent resume={resume || []} />
            </div>
          </div>
          <CreateRes resume={resume || []} userId={userId} />
        </CareerCard>

        {/* 4) Transcript */}
        <CareerCard
          title="Transcript"
          showAddButton={false}
          showDeleteButton={false}
          modalTitle="Manage Transcripts"
          isLoading={isTranscriptLoading}
          onEditModalClose={refreshTranscript}
        >
          <div className="ml-11">
            <PDFViewer
              userId={userId}
              documentType="Transcript"
              refreshTrigger={transcriptRefreshTrigger}
            />
            <div className="mt-2">
              <TranscriptComponent transcript={transcript || []} />
            </div>
          </div>
          <CreateTrans transcript={transcript || []} userId={userId} />
        </CareerCard>

        {/* 5) Checklist */}
        <ChecklistCard userId={userId} />
      </div>
    </div>
  );
};

export default CareerProfile;
