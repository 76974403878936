// src/routes/profile/career/Experience.tsx

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Row } from "react-bootstrap";
import Modal from "../../../components/Modal";
import FormInput from "../../../components/FormInput";
import { DateInput } from "../../../components/DateInput";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import { ExperienceInfo, CreateExpProps } from "../interfaces";

interface ExperienceProps {
  expList: ExperienceInfo[];
}

const ExperienceComponent: React.FC<ExperienceProps> = ({ expList }) => {
  function ExpItem({ info }: { info: ExperienceInfo }) {
    const {
      position,
      company,
      employ_type,
      start_date,
      end_date,
      description,
    } = info;

    const startYear = new Date(start_date).getFullYear();
    const endYear = end_date ? new Date(end_date).getFullYear() : "Present";
    const dateRange = `${startYear} – ${endYear}`;

    return (
      <div className="ml-11 flex flex-row items-start gap-4 mb-6 last:mb-0">
        {/* Main Content */}
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-base font-medium text-gray-900">
                {position}
              </h3>
              <p className="text-md font-normal text-gray-700">
                {company} <span className="mx-1 text-gray-400">•</span>{" "}
                {employ_type}
              </p>
              <div className="flex items-center gap-4 mt-1">
                <p className="text-sm text-gray-500">{dateRange}</p>
              </div>
              {description && (
                <p className="text-sm text-gray-600 mt-2">{description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sortedExpList = [...expList].sort(
    (a, b) =>
      new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
  );

  return (
    <div>
      {sortedExpList.map((info) => (
        <ExpItem key={info.id} info={info} />
      ))}
    </div>
  );
};

export const CreateExp: React.FC<CreateExpProps> = ({
  expList,
  setExperiences,
}) => {
  const BLANK_DATA: ExperienceInfo = {
    relevance: -1,
    position: "",
    company: "",
    employ_type: "Internship",
    start_date: "",
    end_date: null,
    description: "",
  };

  const [experiences, setExp] = useState<ExperienceInfo[]>(
    expList.length ? expList : [BLANK_DATA],
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const handleShowModal = (message: string) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const updateExperienceItem = (
    index: number,
    field: keyof ExperienceInfo,
    value: string | null,
  ) => {
    const updatedList = [...experiences];
    updatedList[index] = {
      ...updatedList[index],
      [field]:
        field === "end_date" && (value === "" || value === null) ? null : value,
    };
    setExp(updatedList);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    index: number,
  ) => {
    const field = e.target.name as keyof ExperienceInfo;
    const value = e.target.value;
    updateExperienceItem(index, field, value);
  };

  const handleRemove = async (itemToRemove: ExperienceInfo) => {
    if (itemToRemove.id) {
      try {
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
        await axiosInterceptor.delete(`/delete_exp_entry/${itemToRemove.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        });
      } catch (error) {
        console.error("Error deleting experience:", error);
      }
    }

    const updatedList = experiences.filter((item) => item !== itemToRemove);
    setExp(updatedList);
    setExperiences(updatedList);
  };

  const addNewEntry = () => {
    setExp([...experiences, { ...BLANK_DATA }]);
  };

  const validateFields = (): boolean => {
    for (const exp of experiences) {
      if (!exp.position.trim()) {
        handleShowModal("The field 'Position' is required.");
        return false;
      }
      if (!exp.company.trim()) {
        handleShowModal("The field 'Company' is required.");
        return false;
      }
      if (!exp.employ_type.trim()) {
        handleShowModal("The field 'Employment Type' is required.");
        return false;
      }
      if (!exp.start_date.trim()) {
        handleShowModal("The field 'Start Date' is required.");
        return false;
      }
      if (!exp.description.trim()) {
        handleShowModal("The field 'Description' is required.");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id") || "";

    if (!validateFields()) return;

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
      for (const exp of experiences) {
        if (exp.id) {
          await axiosInterceptor.patch(`/update_exp/${exp.id}/`, exp, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          });
        } else {
          await axiosInterceptor.post(
            `/create_exp/${user_id}/`,
            { ...exp, user_id },
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
              },
            },
          );
        }
      }
      setExperiences(experiences);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting experiences:", error);
    }
  };

  return (
    <div>
      <Modal show={showModal} setShow={setShowModal} title="Warning">
        <p>{modalMessage}</p>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal>

      <Form onSubmit={handleSubmit}>
        <Stack gap={3}>
          {experiences.map((item, index) => (
            <div key={item.id || index} className="mb-2">
              <Stack gap={2}>
                <FormInput
                  name="position"
                  label="Position"
                  type="text"
                  value={item.position}
                  handler={(e) => handleChange(e, index)}
                  required
                />
                <Form.Group controlId={`formDropdown${index}`}>
                  <Form.Label className="m-0">Employment Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="employ_type"
                    value={item.employ_type}
                    onChange={(e) => handleChange(e, index)}
                    required
                  >
                    <option value="Internship">Internship</option>
                    <option value="New Grad">New Graduate</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                  </Form.Control>
                </Form.Group>
                <FormInput
                  name="company"
                  label="Company Name"
                  type="text"
                  value={item.company}
                  handler={(e) => handleChange(e, index)}
                  required
                />
                <div>
                  <DateInput
                    pos
                    sd={item.start_date ?? ""}
                    ed={item.end_date ?? ""}
                    handler={(e) => handleChange(e, index)}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Current Position"
                    checked={!item.end_date}
                    onChange={(e) => {
                      if (e.target.checked) {
                        updateExperienceItem(index, "end_date", null);
                      } else {
                        updateExperienceItem(index, "end_date", "");
                      }
                    }}
                    className="mt-2"
                  />
                </div>
                <Form.Label className="m-0">Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  onChange={(e) => handleChange(e, index)}
                  value={item.description}
                  required
                />
                <Container style={{ textAlign: "center" }}>
                  <p className="d-inline-block float-right p-0">
                    <Button
                      onClick={() => handleRemove(item)}
                      style={{ padding: "16px", fontSize: "8px" }}
                    >
                      Remove
                    </Button>
                  </p>
                </Container>
              </Stack>
            </div>
          ))}
          <Container className="d-flex justify-content-center align-items-center">
            <Row>
              <Button onClick={addNewEntry} style={{ marginBottom: "25px" }}>
                Add New
              </Button>
              <Button type="submit">Done</Button>
            </Row>
          </Container>
        </Stack>
      </Form>
    </div>
  );
};

export default ExperienceComponent;
