import { useRef, useEffect, FC, HTMLAttributes, RefObject } from "react";

/**
 * Interface for bezier connector props
 * @interface BezierConnectorProps
 * @property {RefObject<HTMLDivElement>} startRef The starting element to draw a bezier curve from
 * @property {RefObject<HTMLDivElement>} endRef The ending element to draw a bezier curve to
 */
export interface BezierConnectorProps {
  startRef: RefObject<HTMLDivElement>;
  endRef: RefObject<HTMLDivElement>;
}

/**
 * draws an SVG path between two elements
 * @component
 * @param {RefObject<HTMLDivElement>} props.startRef a reference to the left endpoint of this connector
 * @param {RefObject<HTMLDivElement>} props.endRef a reference to the right endpoint of this connector
 * @param {string} props.className the class name applied to the svg
 * @returns {ReactElement} a component that draws a line between two divs
 */
const BezierConnector: FC<
  BezierConnectorProps & HTMLAttributes<SVGPathElement>
> = ({ startRef, endRef, className }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const animationRef = useRef<number | null>(null);
  const updatePath = () => {
    if (
      svgRef.current &&
      svgRef.current.parentElement &&
      pathRef.current &&
      startRef.current &&
      endRef.current
    ) {
      const parentRect = svgRef.current.parentElement?.getBoundingClientRect();
      const startRect = startRef.current.getBoundingClientRect();
      const endRect = endRef.current.getBoundingClientRect();

      const startX = startRect.right - parentRect.left;
      const startY = startRect.top + startRect.height / 2 - parentRect.top;
      const endX = endRect.left - parentRect.left;
      const endY = endRect.top + endRect.height / 2 - parentRect.top;

      const dx = Math.abs(endX - startX);
      const controlPointOffset = Math.min(dx / 1.5, 100);

      const d = `M ${startX},${startY} C ${
        startX + controlPointOffset
      },${startY} ${endX - controlPointOffset},${endY} ${endX},${endY}`;
      pathRef.current.setAttribute("d", d);

      svgRef.current.setAttribute(
        "viewBox",
        `0 0 ${parentRect.width} ${parentRect.height}`,
      );
      svgRef.current.style.width = `${parentRect.width}px`;
      svgRef.current.style.height = `${parentRect.height}px`;
      animationRef.current = requestAnimationFrame(updatePath);
    }
  };
  useEffect(() => {
    animationRef.current = requestAnimationFrame(updatePath);

    return () => {
      if (animationRef && animationRef.current !== null)
        cancelAnimationFrame(animationRef.current);
    };
  }, [startRef, endRef]);

  return (
    <svg
      ref={svgRef}
      className="absolute top-0 left-0 w-full h-full bg-opacity-50 pointer-events-none"
    >
      <path
        className={className}
        ref={pathRef}
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default BezierConnector;
