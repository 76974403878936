// src/routes/account/ResetForm.tsx
import React from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

interface ResetFormProps {
  props?: any;
}

function ResetForm(props: ResetFormProps) {
  const [password, setPassword] = React.useState<{ [key: string]: string }>({
    1: "",
    2: "",
  });
  const [validated, setValidated] = React.useState(false);
  const [inSecondStage, setInSecondStage] = React.useState(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "2") {
      if (e.target.value !== password[1]) {
        e.target.setCustomValidity("passwords do not match");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setPassword({ ...password, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        await axiosInterceptor.put("/attempt_password_reset/", {
          password: password[1],
          token: new URLSearchParams(window.location.search).get("token"),
        });
        setInSecondStage(true);
      } catch (error: unknown) {
        // `error` is unknown by default. Cast to `any` to access .response
        const err = error as any;
        console.error(err?.response);
        alert(err?.response?.data?.error || "An error occurred.");
      }
    } else if (!validated) {
      setValidated(true);
    }
  }

  return !inSecondStage ? (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4"
    >
      <h2 className="text-white text-2xl !font-normal text-left mb-3.5">
        Reset Password
      </h2>
      <p className="text-white text-sm !font-normal mb-2">
        Please ensure that your password is relatively complex.
      </p>
      <FloatingLabel label="New password">
        <Form.Control
          name="1"
          type="password"
          placeholder=" "
          required
          value={password[1]}
          onChange={handleChange}
        />
      </FloatingLabel>
      <FloatingLabel label="Confirm new password">
        <Form.Control
          name="2"
          type="password"
          placeholder=" "
          required
          value={password[2]}
          onChange={handleChange}
        />
      </FloatingLabel>
      <Button
        className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
        type="submit"
        disabled={password[1] !== password[2] || !password[1]}
      >
        Reset
      </Button>
    </Form>
  ) : (
    <div className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4 text-center">
      <h2 className="text-white text-2xl !font-normal mb-3.5">
        Password Reset Successful
      </h2>
      <p className="text-white font-light">
        Your password has been successfully updated. You can now log in using
        your new password.
      </p>
      <LinkContainer to="/account/login">
        <Button className="text-[#1B4E99] bg-white border-2 focus:text-[#1B4E99] hover:text-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-md shadow-sm mt-2.5">
          Back to Login
        </Button>
      </LinkContainer>
    </div>
  );
}

export default ResetForm;
