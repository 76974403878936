import React, { useEffect, useState, useRef } from "react";
import RecruitmentBackground from "../../assets/img/landing/process-background.png";

interface TestimonialItem {
  quote: string;
  name: string;
  title: string;
  imageSrc?: string;
}

interface InfiniteMovingCardsProps {
  items: TestimonialItem[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}

const TestimonialsSection: React.FC = () => {
  const testimonials: TestimonialItem[] = [
    {
      quote:
        "The Profile feature really gets to know me. It understands my background, personality, and job preferences to give me personalized recommendations.",
      name: "Emily Johnson",
      title: "Software Developer @ Meta",
    },
    {
      quote:
        "The Test Center is a game-changer. It let me prove my skills in STEM, design, and finance at my own pace, helping me shine in the areas I'm strongest in.",
      name: "Michael Smith",
      title: "Data Analyst @ Amazon",
    },
    {
      quote:
        "The Courses sync perfectly with the Test Center, showing me clear steps to improve my skills and progress toward my goals. It's incredibly motivating!",
      name: "Sophia Lee",
      title: "Product Designer @ Airbnb",
    },
    {
      quote:
        "The Jobs page makes job hunting so easy! It's like a dating app but for careers. Sorting through jobs and matching with recruiters has never been this fun.",
      name: "Liam Brown",
      title: "Quantitative Trader @ Two Sigma",
    },
    {
      quote:
        "Messages is such a friendly feature! It helped me turn matches into real referrals and interviews with recruiters and managers, all while keeping communication stress-free.",
      name: "Olivia Davis",
      title: "Machine Learning Engineer @ OpenAI",
    },
  ];

  return (
    <div className="relative w-full h-screen max-h-[600px]">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${RecruitmentBackground})`,
        }}
      />

      {/* Content */}
      <div className="relative h-full flex flex-col justify-center items-center">
        <div className="w-full max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12 text-white">
            Here's what our candidates say
          </h2>
          <InfiniteMovingCards
            items={testimonials}
            direction="right"
            speed="normal"
            pauseOnHover={true}
            className=""
          />
        </div>
      </div>
    </div>
  );
};

const InfiniteMovingCards: React.FC<InfiniteMovingCardsProps> = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className = "",
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollerRef = useRef<HTMLUListElement | null>(null);
  const [start, setStart] = useState(false);

  useEffect(() => {
    addAnimation();
  }, []);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(
        scrollerRef.current.children,
      ) as HTMLElement[];

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true) as HTMLElement;
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }

  const getDirection = () => {
    if (containerRef.current) {
      containerRef.current.style.setProperty(
        "--animation-direction",
        direction === "left" ? "forwards" : "reverse",
      );
    }
  };

  const getSpeed = () => {
    if (containerRef.current) {
      const speedMap: Record<string, string> = {
        fast: "20s",
        normal: "40s",
        slow: "80s",
      };
      containerRef.current.style.setProperty(
        "--animation-duration",
        speedMap[speed],
      );
    }
  };

  return (
    <div
      ref={containerRef}
      className={`scroller relative z-20 max-w-7xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)] ${className}`}
    >
      <ul
        ref={scrollerRef}
        className={`flex min-w-full shrink-0 gap-6 py-4 w-max flex-nowrap
                    ${start ? "animate-scroll" : ""} 
                    ${pauseOnHover ? "hover:[animation-play-state:paused]" : ""}`}
      >
        {items.map((item, idx) => (
          <li key={idx} className="w-[350px] max-w-full flex-shrink-0">
            <div className="bg-white/90 backdrop-blur rounded-xl p-8 h-full shadow-lg border border-gray-100 transition-all duration-300 hover:shadow-xl hover:scale-[1.02]">
              <div className="flex flex-col h-full">
                <p className="text-gray-900 text-lg mb-6 flex-grow italic">
                  "{item.quote}"
                </p>
                <div className="flex items-center gap-3 pt-4 border-t border-gray-100">
                  <div className="w-10 h-10 rounded-full bg-blue-600 flex items-center justify-center text-white font-medium">
                    {item.name[0]}
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{item.name}</p>
                    <p className="text-sm text-gray-600">{item.title}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestimonialsSection;
