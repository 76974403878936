import { FC, useMemo, useState } from "react";
import Badge from "./Badge";
import {
  PrimaryButton,
  PositiveButton,
  NegativeButton,
  DefaultButton,
  LightNegativeButton,
} from "./Button";
import { PositiveChip, PrimaryChip, NegativeChip } from "./Chip";
import { DropdownToggle } from "./DropdownToggle";
import HorizontalSelection from "./HorizontalSelection";
import Modal from "./Modal";
import ProgressIndicator from "./ProgressIndicator";
import ScreenSpinner from "./ScreenSpinner";
import SegmentedControl from "./SegmentedControl";
import HorizontalProgress from "./HorizontalProgress";

const UILibrary: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const controlValues: string[] = ["Selection A", "Selection B", "Selection C"];
  const [selectedControlValue, setSelectedControlValue] =
    useState<string>("Selection A");
  const [horizontalSelected, setHorizontalSelected] = useState<boolean>(false);
  const [UIProgress, setUIProgress] = useState<number>(0.5);
  const progressIndicatorValues = useMemo(
    () => [-1, 0, 0.1, 0.5, UIProgress],
    [UIProgress],
  );

  return (
    <div className="w-full flex flex-col gap-8 pb-32">
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl text-foreground">Buttons & Toggles</h2>
        <hr></hr>
      </div>
      <div className="grid grid-cols-2 grid-flow-row place-items-stretch gap-2">
        <PrimaryButton message="Button"></PrimaryButton>
        <PositiveButton message="Button"></PositiveButton>
        <NegativeButton message="Button"></NegativeButton>
        <DefaultButton message="Button"></DefaultButton>
        <LightNegativeButton message="Button"></LightNegativeButton>
        <DropdownToggle message="Dropdown Toggle"></DropdownToggle>
        <DefaultButton
          message="Default Button that Opens Modal"
          handleClick={() => setShowModal(!showModal)}
        ></DefaultButton>
        <Modal show={showModal} setShow={setShowModal} title="Modal"></Modal>
      </div>
      <SegmentedControl
        controlItems={controlValues}
        handleValueSelected={(updatedControlValue) =>
          setSelectedControlValue(updatedControlValue)
        }
        defaultValue={selectedControlValue}
      ></SegmentedControl>

      <HorizontalSelection
        isSelected={horizontalSelected}
        handleSelectedChange={setHorizontalSelected}
      >
        <div className="flex flex-col items-start">
          <h2 className="text-lg text-foreground">Horizontal Selection</h2>
          <p className="text-md text-foreground-dimmer">Body Text</p>
        </div>
      </HorizontalSelection>

      <div className="flex flex-col gap-2">
        <h2 className="text-2xl text-foreground">Chips & Badges (?)</h2>
        <hr></hr>
      </div>
      <div className="grid grid-cols-3 grid-flow-row place-items-stretch max-w-96">
        <PrimaryChip message="Chip"></PrimaryChip>
        <PositiveChip message="Chip"></PositiveChip>
        <NegativeChip message="Chip"></NegativeChip>
      </div>

      <Badge text="Placeholder Text"></Badge>
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl text-foreground">Progress</h2>
        <hr></hr>
      </div>
      <div className="flex flex-col gap-8">
        <div className="w-full flex justify-between">
          <DefaultButton
            handleClick={() => setUIProgress(Math.max(0, UIProgress - 0.05))}
            message="Decrement Progress"
          ></DefaultButton>
          <DefaultButton
            handleClick={() => setUIProgress(Math.min(1, UIProgress + 0.1))}
            message="Increment Progress"
          ></DefaultButton>
        </div>
        <HorizontalProgress progress={UIProgress}></HorizontalProgress>
        <div className="flex flex-col">
          {progressIndicatorValues.map((progressValue, index) => (
            <ProgressIndicator
              progress={progressValue}
              showConnectionLine={index !== progressIndicatorValues.length - 1}
              key={index}
              handleClick={() => {
                return;
              }}
            >
              <p className="mb-8">
                Progress Value: {`${progressValue * 100}%`}
              </p>
            </ProgressIndicator>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl text-foreground">Loading Spinner</h2>
        <hr></hr>
      </div>

      <ScreenSpinner></ScreenSpinner>
    </div>
  );
};

export default UILibrary;
