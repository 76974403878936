import React from "react";
import WavePattern from "../../assets/img/landing/landing-page.png";
import Amazon from "../../assets/img/companies/amazon.png";
import Citadel from "../../assets/img/companies/citadel.png";
import Goldman from "../../assets/img/companies/goldman.png";
import JaneStreet from "../../assets/img/companies/jane-street.png";
import JPM from "../../assets/img/companies/jpm.png";
import Microsoft from "../../assets/img/companies/microsoft.png";
import Point72 from "../../assets/img/companies/point72.png";
import Sig from "../../assets/img/companies/sig.png";
import TopNavigation from "./TopNavigation";

const HeroSection: React.FC = () => {
  return (
    <div className="bg-gradient-radial from-[#101832] to-[#15171E] relative overflow-hidden min-h-screen">
      {/* Top Navigation */}
      <TopNavigation showMiddleNav={true} />

      {/* Background Image */}
      <div className="absolute inset-0 w-full h-full z-0">
        <img
          src={WavePattern}
          alt="Background pattern"
          className="absolute w-full h-full object-cover object-center"
        />
      </div>

      {/* Hero Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 pt-20">
        <div className="max-w-2xl">
          <h1 className="text-6xl font-bold mb-12">
            <span className="text-blue-500">{"Find Employees"}</span> <br />
            <span className="text-white">{"to Refer You"}</span>
          </h1>
          <p className="text-gray-300 text-lg mb-6 max-w-xl">
            {
              "We provide 24/7 recommendations of ideal recruiter and referrer matches who are specifically looking for people to refer. We offer comprehensive profiles, tests, and courses so you have a fair opportunity to express yourself"
            }
          </p>
        </div>

        {/* Company Logos Section */}
        <div className="mt-80">
          <main className="relative flex flex-col justify-center overflow-hidden mt-8">
            <div className="w-full max-w-5xl mx-auto">
              <div className="text-center">
                <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
                  <ul className="flex items-center justify-center lg:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll -mx-2">
                    {[
                      Citadel,
                      Amazon,
                      Goldman,
                      JaneStreet,
                      JPM,
                      Microsoft,
                      Sig,
                      Point72,
                    ].map((logo, index) => (
                      <li
                        key={index}
                        className="py-4 h-20 flex items-center justify-center"
                      >
                        <img
                          src={logo}
                          alt={`Company logo ${index + 1}`}
                          className="max-h-full w-auto"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
