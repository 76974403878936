import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import HorizontalSelection from "../../../components/HorizontalSelection";
import { PrimaryButton } from "../../../components/Button";

interface RequestPayload {
  num_samples: number;
  subjects: string[];
}

const TechnicalHome: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);

  // Replace the old subject list with your new categories:
  const subjects = [
    { id: "ai", label: "Artificial Intelligence" },
    { id: "biology", label: "Biology" },
    { id: "biomedical", label: "Biomedical" },
    { id: "chemistry", label: "Chemistry" },
    { id: "computer_science", label: "Computer Science" },
    { id: "data_science", label: "Data Science" },
    { id: "design", label: "Design" },
    { id: "economics", label: "Economics" },
    { id: "electrical", label: "Electrical" },
    { id: "finance", label: "Finance" },
    { id: "hardware", label: "Hardware" },
    { id: "networks", label: "Networks" },
    { id: "physical", label: "Physical" },
    { id: "physics", label: "Physics" },
    { id: "quantitative_finance", label: "Quantitative Finance" },
    { id: "software", label: "Software" },
    { id: "tools", label: "Tools" },
  ];

  const difficulties = [
    { id: "easy", label: "Easy" },
    { id: "medium", label: "Medium" },
    { id: "hard", label: "Hard" },
  ];

  const toggleSubject = (subjectId: string) => {
    setSelectedSubjects((prev) =>
      prev.includes(subjectId)
        ? prev.filter((id) => id !== subjectId)
        : [subjectId, ...prev.slice(0, 2)],
    );
  };

  const handleStartInterview = async () => {
    if (!selectedSubjects.length || !selectedDifficulty) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    const request: RequestPayload = {
      num_samples: 5,
      subjects: selectedSubjects,
    };

    try {
      const response = await axiosInterceptor.post(
        "/sample_questions/",
        request,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken || "",
          },
        },
      );
      // Possibly route to your interview environment
      navigate("/test-center/technical", { state: response.data });
    } catch (error) {
      console.error("Error during interview initiation:", error);
      navigate("/test-center/technical");
    }
  };

  return (
    <div className="min-h-screen bg-[#FFFFFF]">
      <div className="max-w-7xl mx-auto pt-8 pb-16">
        <div className="space-y-6">
          <h2 className="text-2xl font-semibold">
            Technical Exam Customizations
          </h2>

          <div className="bg-[#FFFFFF] rounded-lg space-y-8">
            {/* Instructions */}
            <div className="space-y-2">
              <p className="text-foreground-dimmer max-w-[38rem]">
                <b>Instructions: </b>Choose a difficulty and select subjects
                before you start the interview. This is to tailor your questions
                to your interests and skill level.
              </p>
            </div>

            {/* Subjects Selection */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <label className="block text-2xl font-medium">
                  What subjects would you like to test?
                </label>
                <p className="text-md text-foreground-dimmer">
                  Select up to 3 subjects
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {subjects.map((subject) => (
                  <HorizontalSelection
                    key={subject.id}
                    handleSelectedChange={() => toggleSubject(subject.id)}
                    isSelected={selectedSubjects.includes(subject.id)}
                  >
                    {subject.label}
                  </HorizontalSelection>
                ))}
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex flex-col gap-2">
                <label className="block text-2xl font-medium">
                  How difficult do you want the test to be?
                </label>
                <p className="text-md text-foreground-dimmer">
                  Select a difficulty
                </p>{" "}
              </div>

              <div className="space-y-2">
                {difficulties.map((difficulty) => (
                  <HorizontalSelection
                    key={difficulty.id}
                    handleSelectedChange={() =>
                      setSelectedDifficulty(difficulty.id)
                    }
                    isSelected={selectedDifficulty === difficulty.id}
                  >
                    {difficulty.label}
                  </HorizontalSelection>
                ))}
              </div>
            </div>

            {/* Start Button */}
            <div className="flex justify-end">
              <PrimaryButton
                handleClick={() => handleStartInterview()}
                message="Start Interview"
              ></PrimaryButton>
            </div>

            {/* Alert */}
            {showAlert && (
              <div className="p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                Please choose at least one subject and a difficulty level before
                starting.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalHome;
