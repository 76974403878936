/**
 * MergedJobsPage.tsx
 * The main page for listing companies, searching, and expanding to show MergedCompanyJobsSection.
 */

import React, {
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  useCallback,
} from "react";
import { Button, Spinner } from "react-bootstrap";
import { Search as LucideSearch, Building2, MapPin } from "lucide-react";

import axiosInterceptor from "../../utils/axiosInterceptor";
import MergedCompanyJobsSection from "./MergedCompanyJobsSection";

import { Company, Job, SearchParams } from "./interfaces";
import Badge from "../../components/Badge";

const MergedJobsPage: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);

  // This map will store the jobs for each company, loaded on demand
  const [companyJobsMap, setCompanyJobsMap] = useState<
    Record<number, Job[] | undefined>
  >({});

  // Track which companies are expanded
  const [expandedCompanyIds, setExpandedCompanyIds] = useState<Set<number>>(
    new Set(),
  );

  // Track loading state per-company
  const [companyJobsLoading, setCompanyJobsLoading] = useState<
    Record<number, boolean>
  >({});

  // Search parameters
  const [searchParams, setSearchParams] = useState<SearchParams>({
    companyName: "",
    industry: "",
    location: "",
  });

  /**
   * ------------------------------
   *  Fetch only the list of companies initially
   * ------------------------------
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axiosInterceptor.get<Company[]>(
          "/get_all_companies/",
        );
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    })();
  }, []);

  /**
   * ------------------------------
   *  Handle Search
   * ------------------------------
   */
  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    // If you need server-side searching, add logic here
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  /**
   * ------------------------------
   *  Lazy-load the jobs for a specific company
   * ------------------------------
   */
  const loadCompanyJobs = useCallback(async (companyId: number) => {
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      console.error("No user_id found in localStorage");
      return;
    }
    try {
      setCompanyJobsLoading((prev) => ({ ...prev, [companyId]: true }));

      const response = await axiosInterceptor.get<Job[]>(
        `/get_active_jobs_for_company_with_user_data/${companyId}/${userId}/`,
      );

      setCompanyJobsMap((prev) => ({
        ...prev,
        [companyId]: response.data,
      }));
    } catch (error) {
      console.error("Error loading jobs for company:", error);
    } finally {
      setCompanyJobsLoading((prev) => ({ ...prev, [companyId]: false }));
    }
  }, []);

  /**
   * ------------------------------
   *  Expand/Collapse a company
   * ------------------------------
   */
  const handleToggleCompany = (companyId: number) => {
    setExpandedCompanyIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(companyId)) {
        // collapse
        newSet.delete(companyId);
      } else {
        // expand
        newSet.add(companyId);
        if (companyJobsMap[companyId] === undefined) {
          loadCompanyJobs(companyId);
        }
      }
      return newSet;
    });
  };

  /**
   * ------------------------------
   *  Filter companies by search
   * ------------------------------
   */
  const filteredCompanies = companies.filter((company) => {
    const { companyName, industry, location } = searchParams;
    const matchesCompanyName =
      !companyName ||
      company.company_name.toLowerCase().includes(companyName.toLowerCase());
    const matchesIndustry =
      !industry ||
      company.industry.toLowerCase().includes(industry.toLowerCase());
    const matchesLocation =
      !location ||
      company.location.toLowerCase().includes(location.toLowerCase());
    return matchesCompanyName && matchesIndustry && matchesLocation;
  });

  if (loadingCompanies) {
    return (
      <div className="p-6 text-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2 text-base text-gray-500">
          Loading companies...
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-left pt-4 pl-10 pr-10 gap-4">
      {/* Search Form */}
      <div>
        <form
          onSubmit={handleSearch}
          className="grid grid-cols-1 md:grid-cols-3 gap-4"
        >
          {/* Company Name */}
          <div className="relative">
            <input
              type="text"
              placeholder="Company name"
              className="w-full pl-16 pr-3 py-3 border border-gray-200 rounded-lg text-xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="companyName"
              value={searchParams.companyName}
              onChange={handleInputChange}
            />
            <LucideSearch className="ml-2 absolute w-7 h-7 flex-shrink-0 top-1/2 left-3 -translate-y-1/2 text-[#0059EC]" />
          </div>

          {/* Industry */}
          <div className="relative">
            <input
              type="text"
              placeholder="Industry"
              className="w-full pl-16 pr-3 py-3 border border-gray-200 rounded-lg text-xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="industry"
              value={searchParams.industry}
              onChange={handleInputChange}
            />
            <Building2 className="ml-2 absolute w-7 h-7 flex-shrink-0 top-1/2 left-3 -translate-y-1/2 text-[#0059EC]" />
          </div>

          {/* Location */}
          <div className="relative">
            <input
              type="text"
              placeholder="Location"
              className="w-full pl-16 pr-3 py-3 border border-gray-200 rounded-lg text-xl text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="location"
              value={searchParams.location}
              onChange={handleInputChange}
            />
            <MapPin className="ml-2 absolute w-7 h-7 flex-shrink-0 top-1/2 left-3 -translate-y-1/2 text-[#0059EC]" />
          </div>
        </form>
      </div>

      {/* Companies List */}
      <div className="flex flex-col gap-4">
        {filteredCompanies.length === 0 && (
          <div className="p-6 text-center bg-white rounded-xl border border-gray-200">
            <h3 className="text-base font-medium text-gray-900">
              No matching companies found
            </h3>
            <p className="mt-1 text-md text-gray-600">
              Try adjusting your search filters, or come back later.
            </p>
          </div>
        )}

        {filteredCompanies.map((company) => {
          const isExpanded = expandedCompanyIds.has(company.id);
          const jobsForCompany = companyJobsMap[company.id] || [];
          const isLoadingJobs = companyJobsLoading[company.id];

          return (
            <div
              key={company.id}
              className="bg-white rounded-xl border border-gray-200 overflow-hidden"
            >
              {/* Company Header */}
              <div className="px-4 pb-4 pt-8">
                <div className="flex items-start justify-between">
                  <div className="flex-grow">
                    <h2 className="ml-4 mb-2 text-2xl font-semibold text-gray-900 space-x-2">
                      {company.company_name}
                      <span className="ml-2" />
                      <Badge text="Recruitment" variant="blue" />
                      <Badge text="10 employees" variant="blue" />
                      <Badge text="New York, NY" variant="blue" />
                    </h2>
                  </div>
                  <Button
                    variant="primary"
                    className="flex items-center gap-1.5 bg-blue-800 hover:bg-blue-700 text-white px-3 py-2 rounded-lg text-lg transition-colors
                      focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-700 focus-visible:outline-offset-2"
                    onClick={() => handleToggleCompany(company.id)}
                  >
                    {isExpanded ? "Hide" : "View"}
                  </Button>
                </div>

                {/* Short Company Description */}
                <p className="ml-4 mt-3 text-lg text-gray-600">
                  {company.company_description}
                </p>
              </div>

              {/* Expanded job listings */}
              {isExpanded && (
                <div className="px-4 py-3 bg-white border-t border-gray-100">
                  {isLoadingJobs ? (
                    <div className="flex items-center gap-2 text-gray-500 text-base">
                      <Spinner animation="border" variant="primary" size="sm" />
                      <span>Loading jobs...</span>
                    </div>
                  ) : (
                    <MergedCompanyJobsSection
                      jobs={jobsForCompany}
                      onRateJob={async (jobId, ratingValue) => {
                        try {
                          // Rate the job
                          await axiosInterceptor.post(
                            "/create_or_update_rating/",
                            {
                              user_id: localStorage.getItem("user_id"),
                              job_id: jobId,
                              rating: ratingValue,
                              rating_type: "user_rating_job",
                            },
                          );

                          // Update local state
                          setCompanyJobsMap((prev) => {
                            const currentJobs = prev[company.id];
                            if (!currentJobs) return prev;

                            const updatedJobs = currentJobs.map((job) =>
                              job.id === jobId
                                ? { ...job, user_rating_of_job: ratingValue }
                                : job,
                            );
                            return { ...prev, [company.id]: updatedJobs };
                          });
                        } catch (error) {
                          console.error("Error rating job:", error);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MergedJobsPage;
