import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/logos/all_black.png";
import axiosInterceptor from "../../../utils/axiosInterceptor";

interface Answers {
  [key: string]: number | null;
}

const PersonalitySurvey: React.FC = () => {
  const [answers, setAnswers] = useState<Answers>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<{ type: string; text: string } | null>(
    null,
  );
  const navigate = useNavigate();

  const buttonColors = ["#002359", "#001E9B", "#0019DD", "#003CEC", "#0059EC"];
  // const topMargins = ['0px', '3px', '6px', '3px', '0px'];
  const buttonSizes = [
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
    "w-12 h-12",
  ];

  const questions: string[] = [
    "Am the life of the party.", //1
    "Feel little concern for others.", //2
    "Am always prepared.", //3
    "Get stressed out easily.", //4
    "Have a rich vocabulary.", //5
    "Don't talk a lot.", //6
    "Am interested in people.", //7
    "Leave my belongings around.", //8
    "Am relaxed most of the time.", //9
    "Have difficulty understanding abstract ideas.", //10
    "Feel comfortable around people.", //11
    "Insult people.", //12
    "Pay attention to details.", //13
    "Worry about things.", //14
    "Have a vivid imagination.", //15
    "Keep in the background.", //16
    "Sympathize with others' feelings.", //17
    "Make a mess of things.", //18
    "Seldom feel blue.", //19
    "Am not interested in abstract ideas.", //20
    "Start conversations.", //21
    "Am not interested in other people's problems.", //22
    "Get chores done right away.", //23
    "Am easily disturbed.", //24
    "Have excellent ideas.", //25
    "Have little to say.", //26
    "Have a soft heart.", //27
    "Often forget to put things back in their proper place.", //28
    "Get upset easily.", //29
    "Do not have a good imagination.", //30
    "Talk to a lot of different people at parties.", //31
    "Am not really interested in others.", //32
    "Like order.", //33
    "Change my mood a lot.", //34
    "Am quick to understand things.", //35
    "Don't like to draw attention to myself.", //36
    "Take time out for others.", //37
    "Shirk my duties.", //38
    "Have frequent mood swings.", //39
    "Use difficult words.", //40
    "Don't mind being the center of attention.", //41
    "Feel others' emotions.", //42
    "Follow a schedule.", //43
    "Get irritated easily.", //44
    "Spend time reflecting on things.", //45
    "Am quiet around strangers.", //46
    "Make people feel at ease.", //47
    "Am exacting in my work.", //48
    "Often feel blue.", //49
    "Am full of ideas.", //50,
  ];

  useEffect(() => {
    const fetchPreviousData = async () => {
      try {
        const userId = localStorage.getItem("user_id");
        console.log("Fetching previous data for user_id:", userId);
        if (userId) {
          const response = await axiosInterceptor.get(
            `/save_or_update_user_surveys/${userId}/`,
          );
          console.log("API response:", response);

          if (response.data?.personality_survey) {
            console.log(
              "Personality survey data found:",
              response.data.personality_survey,
            );
            let parsedData: any;
            try {
              parsedData = JSON.parse(response.data.personality_survey);
              console.log(
                "After first JSON.parse, typeof parsedData:",
                typeof parsedData,
              );
              console.log(
                "Parsed personality survey data after first parse:",
                parsedData,
              );
            } catch (parseError) {
              console.error(
                "Error parsing personality_survey JSON:",
                parseError,
              );
              setMessage({
                type: "error",
                text: "Invalid survey data format.",
              });
              setLoading(false);
              return;
            }

            // Check if parsedData is still a string (double-encoded)
            if (typeof parsedData === "string") {
              try {
                parsedData = JSON.parse(parsedData);
                console.log(
                  "After second JSON.parse, typeof parsedData:",
                  typeof parsedData,
                );
                console.log(
                  "Parsed personality survey data after second parse:",
                  parsedData,
                );
              } catch (secondParseError) {
                console.error(
                  "Error parsing personality_survey JSON on second attempt:",
                  secondParseError,
                );
                setMessage({
                  type: "error",
                  text: "Invalid survey data format.",
                });
                setLoading(false);
                return;
              }
            }

            // Now, parsedData should be an object
            if (
              typeof parsedData === "object" &&
              parsedData !== null &&
              !Array.isArray(parsedData)
            ) {
              console.log("Parsed data is a valid object.");
              const initializedAnswers: Answers = {};
              let allKeysPresent = true;

              questions.forEach((q) => {
                const answer = parsedData[q];
                console.log(`Question: "${q}", Answer: ${answer}`);
                if (typeof answer === "number") {
                  initializedAnswers[q] = answer;
                } else {
                  console.warn(
                    `No valid answer found for question: "${q}", setting to null.`,
                  );
                  initializedAnswers[q] = null;
                  allKeysPresent = false;
                }
              });

              if (!allKeysPresent) {
                setMessage({
                  type: "warning",
                  text: "Some answers were missing or invalid. Please complete all questions.",
                });
              }

              console.log("Initialized answers:", initializedAnswers);
              setAnswers(initializedAnswers);
            } else {
              console.error(
                "Parsed personality_survey is neither an object nor an array.",
              );
              setMessage({
                type: "error",
                text: "Invalid survey data format.",
              });
            }
          } else {
            console.log(
              "No previous personality survey data found. Initializing with nulls.",
            );
            const initializedAnswers = questions.reduce<Answers>(
              (acc, q) => ({
                ...acc,
                [q]: null,
              }),
              {},
            );
            setAnswers(initializedAnswers);
          }
        } else {
          console.log("No user_id found in localStorage.");
          const initializedAnswers = questions.reduce<Answers>(
            (acc, q) => ({
              ...acc,
              [q]: null,
            }),
            {},
          );
          setAnswers(initializedAnswers);
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
      } finally {
        setLoading(false);
        console.log("Loading state set to false.");
      }
    };
    fetchPreviousData();
  }, []); // Removed 'questions' from dependency array

  const handleAnswerChange = (question: string, value: number) => {
    console.log(
      `Changing answer for question: "${question}" to value: ${value}`,
    );
    setAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [question]: value,
      };
      return updatedAnswers;
    });
  };

  const handleSave = async () => {
    console.log("Initiating save process...");
    const sanitizedAnswers = Object.fromEntries(
      Object.entries(answers).filter(
        ([q, a]) => questions.includes(q) && a !== null,
      ),
    );
    console.log("Sanitized answers to be saved:", sanitizedAnswers);

    if (Object.keys(sanitizedAnswers).length !== questions.length) {
      console.error("Not all questions have been answered.");
      setMessage({
        type: "error",
        text: "Please answer all the questions before saving.",
      });
      return;
    }

    setSaving(true);
    setMessage(null);
    console.log("Saving state set to true.");

    const userId = localStorage.getItem("user_id");
    if (!userId) {
      console.error("User ID not found in localStorage.");
      setMessage({
        type: "error",
        text: "User ID not found. Please log in again.",
      });
      setSaving(false);
      return;
    }

    try {
      console.log(`Attempting to PATCH survey data for user_id: ${userId}`);
      const response = await axiosInterceptor.patch(
        `/save_or_update_user_surveys/${userId}/`,
        { personality_survey: JSON.stringify(sanitizedAnswers) },
      );
      console.log("PATCH response:", response);
      if (response.status === 200) {
        console.log(
          "Survey data successfully updated. Navigating to survey-success.",
        );
        navigate("/survey-success");
      }
    } catch (error: any) {
      console.error("Error during PATCH request:", error);
      if (error.response?.status === 404) {
        try {
          console.log(
            `PATCH failed with 404. Attempting to POST survey data for user_id: ${userId}`,
          );
          const postResponse = await axiosInterceptor.post(
            `/save_or_update_user_surveys/${userId}/`,
            { personality_survey: JSON.stringify(sanitizedAnswers) },
          );
          console.log("POST response:", postResponse);
          if (postResponse.status === 201 || postResponse.status === 200) {
            console.log(
              "Survey data successfully created. Navigating to survey-success.",
            );
            navigate("/survey-success");
          }
        } catch (postError) {
          console.error("Error during POST request:", postError);
          setMessage({
            type: "error",
            text: "Failed to submit survey responses.",
          });
        }
      } else {
        setMessage({
          type: "error",
          text: "Failed to update survey responses.",
        });
      }
    } finally {
      setSaving(false);
      console.log("Saving state set to false.");
    }
  };

  if (loading) {
    console.log("Loading survey...");
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl">Loading survey...</p>
      </div>
    );
  }

  return (
    <div className="bg-[#FFFFFF] max-w-4xl mx-auto p-8">
      <div className="mb-8">
        <div className="flex items-center justify-start mb-2">
          <img src={Logo} alt="Logo" className="h-10" />
        </div>
        <h1 className="text-3xl font-bold mb-4">Personality Test</h1>
        <p className="text-gray-600">
          <strong>Instructions:</strong> For each statement, rate how much you
          agree with statement on a scale of 1-5, where:
        </p>
        <div className="text-gray-600 mt-2 space-y-1">
          <p>1 = Disagree</p>
          <p>2 = Slightly Disagree</p>
          <p>3 = Neutral</p>
          <p>4 = Slightly Agree</p>
          <p>5 = Agree</p>
        </div>
        <br></br>
        <p className="text-gray-600">
          <strong>Note:</strong> The questions should be read starting with the
          word "I" to refer to yourself. So it goes I... statement
        </p>
      </div>

      {message && (
        <div
          className={`mb-6 p-4 rounded ${message.type === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}
        >
          {message.text}
        </div>
      )}

      <div className="space-y-12">
        {questions.map((question, qIndex) => (
          <div key={qIndex} className="space-y-4">
            <h2 className="text-xl font-medium">{question}</h2>
            <div className="flex items-center justify-between px-4">
              <span
                className="text-sm font-medium"
                style={{ color: buttonColors[0] }}
              >
                Disagree
              </span>
              <div className="flex-1 flex justify-center space-x-8">
                {[...Array(5)].map((_, i) => {
                  const isSelected = answers[question] === i + 1;
                  const borderColor = buttonColors[i];
                  // const topMargin = topMargins[i];

                  return (
                    <button
                      key={i}
                      onClick={() => handleAnswerChange(question, i + 1)}
                      className={`${buttonSizes[i]} rounded-full border-2 transition-colors duration-200 cursor-pointer`}
                      style={{
                        border: `1px solid ${borderColor}`,
                        // borderColor: borderColor,
                        backgroundColor: isSelected ? borderColor : "white",
                        // marginTop: topMargin
                      }}
                      aria-label={`Option ${i + 1} for "${question}"`}
                    />
                  );
                })}
              </div>
              <span
                className="text-sm font-medium"
                style={{ color: buttonColors[4] }}
              >
                Agree
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-16">
        <button
          onClick={handleSave}
          disabled={saving}
          className={`w-full bg-blue-500 text-white text-lg font-medium py-4 rounded-lg transition-opacity ${
            saving ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default PersonalitySurvey;
