import {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  useState,
} from "react";

/**
 * Interface for DropdownToggleProps
 * @interface DropdownToggleProps
 * @property {ReactElement<SVGElement>} [icon] Optional SVG icon element to display in the button
 * @property {string} message Text to display on the button
 * @property {MouseEventHandler<HTMLButtonElement>} [handleClick] Optional click event handler for the button
 */
interface DropdownToggleProps extends HTMLAttributes<HTMLButtonElement> {
  message: string;
}
/**
 * DropdownToggle component
 * @component
 * @param {Object} props The props for the DropdownToggle component
 * @param {ReactElement<SVGElement>} [props.icon] Optional SVG icon element to display in the button
 * @param {string} props.message Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] Optional click event handler for the button
 * @param {React.Ref<HTMLButtonElement>} ref Ref forwarded to the button element
 * @returns {ReactElement} A styled button element that can be used as a dropdown toggle
 */
const DropdownToggleRenderFunction: ForwardRefRenderFunction<
  HTMLButtonElement,
  DropdownToggleProps & HTMLAttributes<HTMLButtonElement>
> = ({ message, onClick }, ref) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  return (
    <button
      ref={ref}
      className="rounded-sm bg-background hover:bg-background-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 border-[1px] border-background-dimmest border-solid transition-colors w-min 
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-foreground focus-visible:outline-offset-2"
      onClick={(e) => {
        if (onClick) onClick(e);
        setMenuVisible(!menuVisible);
      }}
    >
      <p className="text-foreground text-nowrap">{message}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className={`${
          menuVisible ? "-rotate-180" : ""
        } transition-transform border-e-accent-positive-dimmer ease-out-expo duration-700`}
      >
        <path
          d="M3.50049 5.74996L8.00049 10.25L12.5005 5.74996"
          stroke="#171717"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export const DropdownToggle = forwardRef(DropdownToggleRenderFunction);
