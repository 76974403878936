// consumer/JobRequirementsDisplay.tsx
import React from "react";
import { profileIcons } from "../../icons";

const {
  career: {
    personality: BehavioralIcon,
    experience: BackgroundIcon,
    skills: TechnicalIcon,
  },
} = profileIcons;

interface Requirement {
  name: string;
  criticality: "Critical" | "Important" | "Helpful";
}

interface JobRequirements {
  behavioral: Requirement[];
  background: Requirement[];
  technical: Requirement[];
}

// A quick dummy set if you don't have real data yet:
export const dummyJobRequirements: JobRequirements = {
  behavioral: [
    { name: "Problem Solving", criticality: "Critical" },
    { name: "Communication", criticality: "Critical" },
    { name: "Teamwork", criticality: "Critical" },
  ],
  background: [
    { name: "Research Experience", criticality: "Critical" },
    { name: "Project Leadership", criticality: "Critical" },
    { name: "Published Papers", criticality: "Critical" },
  ],
  technical: [
    { name: "Machine Learning", criticality: "Critical" },
    { name: "Python", criticality: "Critical" },
    { name: "Data Science", criticality: "Critical" },
    { name: "Deep Learning", criticality: "Critical" },
  ],
};

interface RequirementListItemProps {
  name: string;
  criticality: string;
}

const RequirementListItem: React.FC<RequirementListItemProps> = ({
  name,
  criticality,
}) => (
  <div className="ml-10 flex items-center justify-between mr-10">
    <span className="text-base text-gray-700 w-3/5">{name}</span>
    <span className="text-base text-gray-500 text-right w-2/5">
      {criticality}
    </span>
  </div>
);

interface RequirementsSectionProps {
  title: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  requirements: Requirement[];
}

const RequirementsSection: React.FC<RequirementsSectionProps> = ({
  title,
  icon: Icon,
  requirements,
}) => (
  <div>
    <div className="flex items-start gap-2 mb-4">
      <Icon className="w-7 h-7 text-[#0059EC] flex-shrink-0" />
      <h4 className="text-xl font-semibold text-gray-900">{title}</h4>
    </div>
    <div className="space-y-2">
      {requirements.map((req) => (
        <RequirementListItem
          key={req.name}
          name={req.name}
          criticality={req.criticality}
        />
      ))}
    </div>
  </div>
);

interface JobRequirementsDisplayProps {
  requirements: JobRequirements;
}

const JobRequirementsDisplay: React.FC<JobRequirementsDisplayProps> = ({
  requirements,
}) => {
  const sections = [
    {
      title: "Behavioral",
      icon: BehavioralIcon,
      data: requirements.behavioral,
    },
    {
      title: "Background",
      icon: BackgroundIcon,
      data: requirements.background,
    },
    {
      title: "Technical",
      icon: TechnicalIcon,
      data: requirements.technical,
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {sections.map((section) => (
          <RequirementsSection
            key={section.title}
            title={section.title}
            icon={section.icon}
            requirements={section.data}
          />
        ))}
      </div>
    </div>
  );
};

export default JobRequirementsDisplay;
