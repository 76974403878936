import { Filter } from "lucide-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Avatar from "boring-avatars";
import { useState } from "react";

// Candidate interface
interface Candidate {
  id: number;
  name: string;
  university: string;
  coursesCompleted: number;
  jobsApplied: number;
  avatarColor?: string[];
}

// Simple stats for universities
interface UniversityStat {
  school: string;
  total: number;
}

// Table rows for page analytics
interface PageAnalyticsRow {
  activity: string;
  started: number;
  completed: number;
  percentStarted: string;
  percentCompleted: string;
  avgScore?: string; // only used in some views
}

// Mock line chart data
const monthlyUsers = [
  { month: "June", count: 100 },
  { month: "July", count: 200 },
  { month: "Aug", count: 300 },
  { month: "Sep", count: 400 },
  { month: "Oct", count: 700 },
  { month: "Nov", count: 600 },
  { month: "Dec", count: 500 },
];

// ----- Old “Top Performers” style data -----
const topCandidatesData: Record<string, Candidate[]> = {
  "Data Science": [
    {
      id: 1,
      name: "Jack Cook",
      university: "Cornell University",
      coursesCompleted: 19,
      jobsApplied: 10,
    },
    {
      id: 2,
      name: "Christian Lizardi",
      university: "Cornell University",
      coursesCompleted: 20,
      jobsApplied: 15,
    },
    {
      id: 3,
      name: "Simon Ilincev",
      university: "Cornell University",
      coursesCompleted: 30,
      jobsApplied: 25,
    },
    {
      id: 4,
      name: "Nick Awertschenko",
      university: "Dartmouth University",
      coursesCompleted: 10,
      jobsApplied: 5,
    },
  ],
  "Software Engineer": [
    {
      id: 1,
      name: "Simon Ilincev",
      university: "Cornell University",
      coursesCompleted: 30,
      jobsApplied: 25,
    },
    {
      id: 2,
      name: "Nick Awertschenko",
      university: "Dartmouth University",
      coursesCompleted: 10,
      jobsApplied: 5,
    },
    {
      id: 3,
      name: "Jack Cook",
      university: "Cornell University",
      coursesCompleted: 19,
      jobsApplied: 10,
    },
    {
      id: 4,
      name: "Christian Lizardi",
      university: "Cornell University",
      coursesCompleted: 20,
      jobsApplied: 15,
    },
  ],
};

// Page analytics (Profile, Test Center, Course Map)
const pageAnalyticsProfile: PageAnalyticsRow[] = [
  {
    activity: "Complete Profile",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
  {
    activity: "Attach Resume",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
  {
    activity: "Add Skills",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
];

const pageAnalyticsTestCenter: PageAnalyticsRow[] = [
  {
    activity: "Background Section",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
    avgScore: "85%",
  },
  {
    activity: "Behavioral Section",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
    avgScore: "85%",
  },
  {
    activity: "Technical Section",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
    avgScore: "85%",
  },
];

const pageAnalyticsCourseMap: PageAnalyticsRow[] = [
  {
    activity: "Computer Foundations",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
  {
    activity: "Math Foundations",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
  {
    activity: "Data Foundations",
    started: 100,
    completed: 60,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
];

const pageAnalyticsJobFinder: PageAnalyticsRow[] = [
  {
    activity: "Search Jobs",
    started: 120,
    completed: 100,
    percentStarted: "83%",
    percentCompleted: "50%",
  },
  {
    activity: "Save Job Listings",
    started: 90,
    completed: 70,
    percentStarted: "78%",
    percentCompleted: "40%",
  },
  {
    activity: "Apply to Jobs",
    started: 80,
    completed: 50,
    percentStarted: "62%",
    percentCompleted: "25%",
  },
];

const pageAnalyticsRecruitmentProgress: PageAnalyticsRow[] = [
  {
    activity: "Schedule Interviews",
    started: 50,
    completed: 30,
    percentStarted: "60%",
    percentCompleted: "20%",
  },
  {
    activity: "Review Applications",
    started: 70,
    completed: 50,
    percentStarted: "71%",
    percentCompleted: "35%",
  },
  {
    activity: "Extend Offers",
    started: 40,
    completed: 20,
    percentStarted: "50%",
    percentCompleted: "10%",
  },
];

const pageAnalyticsMessages: PageAnalyticsRow[] = [
  {
    activity: "Send Messages",
    started: 200,
    completed: 180,
    percentStarted: "90%",
    percentCompleted: "80%",
  },
  {
    activity: "Respond to Messages",
    started: 150,
    completed: 120,
    percentStarted: "80%",
    percentCompleted: "60%",
  },
  {
    activity: "Message Follow-ups",
    started: 100,
    completed: 70,
    percentStarted: "70%",
    percentCompleted: "35%",
  },
];

const topUniversities: UniversityStat[] = [
  { school: "Cornell University", total: 40 },
  { school: "Dartmouth University", total: 30 },
  { school: "New York University", total: 20 },
];
/** Candidates Page (Leaderboard + Chart + Page Analytics) */
const CandidatesPage = () => {
  const [currentRole, setCurrentRole] = useState<
    "Data Science" | "Software Engineer"
  >("Software Engineer");
  const [pageAnalyticsView, setPageAnalyticsView] = useState<
    | "Profile"
    | "Test Center"
    | "Course Map"
    | "Job Finder"
    | "Recruitment Progress"
    | "Messages"
  >("Profile");

  // Filter by university
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(
    null,
  );

  // Collect possible unis from all data
  const possibleUniversities = Array.from(
    new Set(
      Object.values(topCandidatesData).flatMap((candidates) =>
        candidates.map((c) => c.university),
      ),
    ),
  );

  // Filter the leaderboard based on the selected role & university
  const leaderboard = topCandidatesData[currentRole].filter(
    (candidate) =>
      !selectedUniversity || candidate.university === selectedUniversity,
  );

  // Decide which analytics data to show
  let pageAnalyticsData: PageAnalyticsRow[];
  if (pageAnalyticsView === "Test Center") {
    pageAnalyticsData = pageAnalyticsTestCenter;
  } else if (pageAnalyticsView === "Course Map") {
    pageAnalyticsData = pageAnalyticsCourseMap;
  } else if (pageAnalyticsView === "Job Finder") {
    pageAnalyticsData = pageAnalyticsJobFinder;
  } else if (pageAnalyticsView === "Recruitment Progress") {
    pageAnalyticsData = pageAnalyticsRecruitmentProgress;
  } else if (pageAnalyticsView === "Messages") {
    pageAnalyticsData = pageAnalyticsMessages;
  } else {
    pageAnalyticsData = pageAnalyticsProfile;
  }

  return (
    <div className="space-y-6">
      {/* First row: Chart & Leaderboard */}
      <div className="grid grid-cols-2 gap-6">
        {/* CARD: Users Chart */}
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-lg font-semibold">Users</h2>
            <span className="text-sm text-gray-500">14,312</span>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={monthlyUsers}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="month" axisLine={false} tickLine={false} />
                <YAxis axisLine={false} tickLine={false} />
                <Tooltip />
                <Bar
                  dataKey="count"
                  fill="#2563EB"
                  radius={[4, 4, 0, 0]} // Adds rounded corners to the top of bars
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* CARD: Top Candidates (Leaderboard) */}
        <div className="bg-white rounded-xl shadow-sm p-4 relative">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Top Candidates</h2>
            <div className="flex items-center gap-2">
              {/* Role select */}
              <select
                className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md focus:outline-none
                             focus:border-blue-400 focus:ring focus:ring-blue-50"
                value={currentRole}
                onChange={(e) => setCurrentRole(e.target.value as any)}
              >
                <option value="Software Engineer">Software Engineer</option>
                <option value="Data Science">Data Science</option>
              </select>
              {/* Filter button */}
              <button
                className="p-2 hover:bg-gray-100 rounded-full"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                <Filter size={20} />
              </button>
              {/* Filter dropdown */}
              {isFilterOpen && (
                <div className="absolute right-4 top-16 w-48 bg-white border border-gray-100 rounded-md shadow-lg z-10">
                  {possibleUniversities.map((uni) => {
                    const count = topCandidatesData[currentRole].filter(
                      (c) => c.university === uni,
                    ).length;
                    // Only show if the current role has candidates from that uni
                    if (count === 0) return null;
                    return (
                      <div
                        key={uni}
                        onClick={() =>
                          setSelectedUniversity(
                            selectedUniversity === uni ? null : uni,
                          )
                        }
                        className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center gap-2 text-sm"
                      >
                        <input
                          type="radio"
                          checked={selectedUniversity === uni}
                          readOnly
                          className="h-4 w-4"
                        />
                        <span>
                          {uni} ({count})
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* Leaderboard Table */}
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-100 text-gray-500">
                <th className="py-2 text-left font-medium">#</th>
                <th className="py-2 text-left font-medium">Name</th>
                <th className="py-2 text-left font-medium">
                  Courses Completed
                </th>
                <th className="py-2 text-left font-medium">Jobs Applied</th>
                <th className="py-2"></th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map((candidate, index) => (
                <tr
                  key={candidate.id}
                  className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2">{index + 1}</td>
                  <td className="py-2">
                    <div className="flex items-center gap-2">
                      <Avatar
                        size={24}
                        name={candidate.name}
                        variant="beam"
                        colors={
                          candidate.avatarColor || [
                            "#E3E3E3",
                            "#C7C7C7",
                            "#999999",
                          ]
                        }
                      />
                      <div>
                        <p>{candidate.name}</p>
                        <p className="text-xs text-gray-400">
                          {candidate.university}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-2">{candidate.coursesCompleted}</td>
                  <td className="py-2">{candidate.jobsApplied}</td>
                  <td className="py-2 text-right">
                    <button className="text-[#0059EC] hover:underline text-xs">
                      View Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Second row: Page Analytics & Top Universities */}
      <div className="grid grid-cols-3 gap-6">
        {/* CARD: Page Analytics */}
        <div className="col-span-2 bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Page Analytics</h2>
            <select
              className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md focus:outline-none
                          focus:border-blue-400 focus:ring focus:ring-blue-50"
              value={pageAnalyticsView}
              onChange={(e) => setPageAnalyticsView(e.target.value as any)}
            >
              <option value="Profile">Profile</option>
              <option value="Test Center">Test Center</option>
              <option value="Course Map">Course Map</option>
              <option value="Job Finder">Job Finder</option>
              <option value="Recruitment Progress">Recruitment Progress</option>
              <option value="Messages">Messages</option>
            </select>
          </div>
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-100 text-gray-500">
                <th className="py-2 text-left font-medium">Activity</th>
                <th className="py-2 text-left font-medium">Started</th>
                <th className="py-2 text-left font-medium">Completed</th>
                <th className="py-2 text-left font-medium">% Started</th>
                <th className="py-2 text-left font-medium">% Completed</th>
              </tr>
            </thead>
            <tbody>
              {pageAnalyticsData.map((row, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2">{row.activity}</td>
                  <td className="py-2">{row.started}</td>
                  <td className="py-2">{row.completed}</td>
                  <td className="py-2">{row.percentStarted}</td>
                  <td className="py-2">{row.percentCompleted}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* CARD: Top Universities */}
        <div className="bg-white rounded-xl shadow-sm p-4">
          <h2 className="text-lg font-semibold mb-4">Top Universities</h2>
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-100 text-gray-500">
                <th className="py-2 text-left font-medium">School</th>
                <th className="py-2 text-right font-medium">Total</th>
              </tr>
            </thead>
            <tbody>
              {topUniversities.map((uni, index) => (
                <tr
                  key={uni.school}
                  className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2">
                    {index + 1}. {uni.school}
                  </td>
                  <td className="py-2 text-right">{uni.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CandidatesPage;
